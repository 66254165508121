import { getCaretPosition, scanCaretPath } from './CaretPath';
import {
    FormattingType,
    getClosestEditorElement,
    getPagesInvolved,
    getSelectedNodes,
    isInsideContextCatalog,
    isInsideContextComputerRelated,
    isInsideContextInspectionError,
    isInsideContextMath,
    isInsideContextSuppression,
    isInsideFormattingBold,
    isInsideFormattingItalic,
    isInsideFormattingSub,
    isInsideFormattingSup,
    isInsideFormattingUnderline,
} from './EditorUtil';
import { ZERO_WIDTH_NB_CHAR } from '../KeyboardModule';
import { getPageParagraphs } from './PageManipulation';
import { getTinyMCESelectedHTML } from '../../../../util/GetTinyMCESelectedHTML';
import { modifyTextCase as modifyTextCaseFn } from '../../ModifyTextCase';
import { elementCanBeInsertedAtSelection } from './EditorElements';
import { removeNonPrintableChars } from './ShowNonPrintableChars';

import { getClosestElementAlignment } from './editor-element/EditorElementAlignment';
import { isInnerContextElement } from './EditorElements';
import { normalizeSpaces } from '../../../../util/TextUtil';

/**
 * @param node {Node}
 * @returns {Object<number, AlignmentType>}
 */
export function getAlignmentMap(node) {
    const alignmentMap = {};
    scanCaretPath(node, null, null, (path, paragraph, word, node) => {
        if (alignmentMap[paragraph] === undefined) {
            const alignment = getClosestElementAlignment(node);
            if (alignment) {
                alignmentMap[paragraph] = alignment.getAttribute('type');
            } else {
                alignmentMap[paragraph] = null;
            }
        }
    });
    return alignmentMap;
}

/**
 * @param formatType {FormattingType | string}
 * @returns {Node}
 */
export function createFormattingContainer(formatType) {
    switch (formatType) {
        case FormattingType.BOLD:
            return document.createElement('strong');
        case FormattingType.ITALIC:
            return document.createElement('em');
        case FormattingType.UNDERLINE: {
            const span = document.createElement('span');
            span.style.textDecoration = 'underline';
            return span;
        }
        case FormattingType.SUB:
            return document.createElement('sub');
        case FormattingType.SUP:
            return document.createElement('sup');
        case FormattingType.CONTEXT_SUPPRESSION: {
            const context = document.createElement('editor-element');
            context.setAttribute('type', 'suppression');
            return context;
        }
        case FormattingType.CONTEXT_MATH: {
            const context = document.createElement('editor-element');
            context.setAttribute('type', 'math');
            return context;
        }
        case FormattingType.CONTEXT_COMPUTER_RELATED: {
            const context = document.createElement('editor-element');
            context.setAttribute('type', 'computer-related');
            return context;
        }
        case FormattingType.CONTEXT_CATALOG: {
            const context = document.createElement('editor-element');
            context.setAttribute('type', 'catalog');
            return context;
        }
        case FormattingType.REVISION_ERROR: {
            const context = document.createElement('editor-element');
            context.setAttribute('type', 'revision-error');
            return context;
        }
        default:
            throw new Error(`Unsupported format: ${formatType}`);
    }
}

/**
 * @param node {Node}
 * @returns {FormattingType[]}
 */
export function getNodeFormatting(node) {
    const formatting = [];
    if (isInsideFormattingBold(node)) formatting.push(FormattingType.BOLD);
    if (isInsideFormattingItalic(node)) formatting.push(FormattingType.ITALIC);
    if (isInsideFormattingUnderline(node))
        formatting.push(FormattingType.UNDERLINE);
    if (isInsideFormattingSub(node)) formatting.push(FormattingType.SUB);
    if (isInsideFormattingSup(node)) formatting.push(FormattingType.SUP);
    if (isInsideContextSuppression(node))
        formatting.push(FormattingType.CONTEXT_SUPPRESSION);
    if (isInsideContextMath(node)) formatting.push(FormattingType.CONTEXT_MATH);
    if (isInsideContextComputerRelated(node))
        formatting.push(FormattingType.CONTEXT_COMPUTER_RELATED);
    if (isInsideContextCatalog(node))
        formatting.push(FormattingType.CONTEXT_CATALOG);
    if (isInsideContextInspectionError(node))
        formatting.push(FormattingType.REVISION_ERROR);
    return formatting;
}

/**
 * @param node {HTMLElement | HTMLElement[] | DocumentFragment}
 * @param allElements {boolean | null}
 * @returns {{ fragment: DocumentFragment, formatting: Object<FormattingType, number[]> }}
 */
export function removeFormatting(node, allElements = false) {
    if (Array.isArray(node)) {
        const fragment = document.createDocumentFragment();
        for (let element of node) {
            fragment.appendChild(element);
        }
        node = fragment;
    }

    let formatting = {};

    function addFormatting(pathIdx, formattingType) {
        let array = formatting[formattingType] || [];
        if (!array.includes(pathIdx)) {
            array.push(pathIdx);
        }
        formatting[formattingType] = array;
    }

    const nodePath = scanCaretPath(
        node,
        null,
        null,
        (path, paragraph, word, node) => {
            for (const formattingType of getNodeFormatting(node)) {
                addFormatting(path.length - 1, formattingType);
            }
        },
        allElements,
    );
    const alignmentMap = getAlignmentMap(node);

    let textNode = null;
    // reconstruct without any formatting
    const fragment = document.createDocumentFragment();

    for (let element of nodePath) {
        if (element === '\n') {
            fragment.appendChild(document.createElement('br'));
            if (textNode?.textContent.endsWith(' ')) {
                textNode.textContent =
                    textNode.textContent.substring(
                        0,
                        textNode.textContent.length - 1,
                    ) + '\u00A0';
            }
            textNode = null;
        } else if (!allElements && isInnerContextElement(element)) {
            fragment.appendChild(element.cloneNode(true));
            textNode = null;
        } else {
            if (element === ' ' && !textNode) {
                element = '\u00A0';
            }
            if (!textNode) {
                textNode = document.createTextNode(element);
                fragment.appendChild(textNode);
            } else {
                if (element === ' ' && textNode.textContent.endsWith(' ')) {
                    element = '\u00A0';
                }
                textNode.textContent += element;
            }
        }
    }

    for (let positions of Object.values(formatting)) {
        let toAdd = [];
        for (let position of positions) {
            if (
                positions.includes(position - 2) &&
                nodePath[position - 2] === ' '
            ) {
                toAdd.push(position - 1);
            }
        }
        if (toAdd.length) {
            positions = positions.concat(toAdd).sort((a, b) => a - b);
        }
    }

    // reconstruct alignment
    const paragraphs = getPageParagraphs(fragment);
    for (const paragraphIdx in alignmentMap) {
        const alignmentType = alignmentMap[paragraphIdx];
        if (!alignmentType) continue;
        const paragraph = paragraphs[paragraphIdx];
        if (!paragraph) break;
        const firstElement = paragraph[0];
        if (!firstElement) continue;
        const lastElement = paragraph[paragraph.length - 1];
        const alignmentContainer = document.createElement('editor-element');
        firstElement.replaceWith(alignmentContainer);
        alignmentContainer.setAttribute('type', alignmentType);
        if (paragraph.length === 1) {
            alignmentContainer.appendChild(firstElement);
        } else {
            for (let i = 0; i < paragraph.length - 1; i++) {
                // ignores the last item (br)
                const element = paragraph[i];
                alignmentContainer.appendChild(element);
            }
        }

        // br signs paragraph end, not a line break
        // the alignment container is a block element, to not create an adicional non-existent line this br must be hided
        // not pretty, but avoid a massive refactor of the system
        if (lastElement && lastElement.style && lastElement.tagName === 'BR') {
            lastElement.style.display = 'none';
        } else {
            console.warn(
                `Unexpected element (or nonexistent) in alignment container instead paragraph break: ${lastElement}}`,
            );
        }

        if (
            alignmentContainer.lastChild?.nodeType !== Node.TEXT_NODE ||
            alignmentContainer.lastChild?.textContent !== ZERO_WIDTH_NB_CHAR
        ) {
            const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            alignmentContainer.appendChild(textNode);
        }
    }
    removeFormattingIslands(nodePath, formatting);

    return {
        fragment,
        formatting,
    };
}

/**
 * @param page {HTMLElement | DocumentFragment}
 * @param formatting { Object<FormattingTypeValue, number[]> }
 * @param callbackFn {function(container: HTMLElement, formattingType: FormattingTypeValue, position: number, length: number, innerContextElement: HTMLElement | string) |  undefined}
 */
export function applyFormatting(page, formatting, callbackFn = null) {
    for (let formattingType of Object.keys(FormattingType)) {
        formattingType = FormattingType[formattingType];
        const positions = formatting[formattingType];
        if (!positions) continue;
        const callback = (path, paragraph, word, node, nodeOffset) => {
            const position = path.length - 1;
            const currentPath = path[path.length - 1];
            if (positions.includes(position)) {
                const container = createFormattingContainer(formattingType);
                if (isInnerContextElement(currentPath)) {
                    node.replaceWith(container);
                    container.appendChild(currentPath);
                    positions.splice(positions.indexOf(position), 1);
                    if (callbackFn)
                        callbackFn(
                            container,
                            formattingType,
                            position,
                            1,
                            currentPath,
                        );
                } else {
                    if (currentPath === '\n') return true;
                    let endPosition = position + 1;
                    while (positions.includes(endPosition)) {
                        endPosition++;
                    }
                    let length = endPosition - position;

                    // this happens when a word is broken in this session
                    if (length > node.textContent.length - nodeOffset) {
                        length = node.textContent.length - nodeOffset;
                    }
                    if (length <= 0) {
                        // avoid deadlock
                        return;
                    }
                    positions.splice(positions.indexOf(position), length);
                    container.textContent = node.textContent.substring(
                        nodeOffset,
                        nodeOffset + length,
                    );
                    const clone = node.cloneNode(true);
                    node.textContent = node.textContent.substring(
                        0,
                        nodeOffset,
                    );
                    clone.textContent = clone.textContent.substring(
                        nodeOffset + length,
                    );
                    node.after(container, clone);
                    if (callbackFn)
                        callbackFn(
                            container,
                            formattingType,
                            position,
                            length,
                            currentPath,
                        );
                }
                if (positions.length) {
                    scanCaretPath(page, null, null, callback);
                }
                return false;
            }
        };
        scanCaretPath(page, null, null, callback);
    }
}

/**
 * @param path {(string | HTMLElement)[]}
 * @param formatting {Object<FormattingType, number[]>}
 */
function removeFormattingIslands(path, formatting) {
    for (const type in formatting) {
        const positions = formatting[type];
        let last = null;
        let positionsToAdd = [];
        let start = false;
        for (let position of positions) {
            if (start && (last === null || position - last === 2)) {
                if (path[position - 1] === ' ') {
                    positionsToAdd.push(position - 1);
                }
            }
            last = position;
            start = true;
        }
        if (positionsToAdd.length) {
            formatting[type] = [...positions, ...positionsToAdd].sort(
                (a, b) => a - b,
            );
        }
    }
}

/**
 * @param node {HTMLElement | Node}
 */
export function removeSpaceFromContextMath(node) {
    const editorElements = node.querySelectorAll('editor-element[type="math"]');
    /**
     *@type {Node}
     */
    let element;
    for (element of editorElements) {
        const text = element.textContent;
        let spacesAtStart = '';
        let spacesAtEnd = '';
        let i = 0;
        while (normalizeSpaces(text[i]) === ' ') spacesAtStart += text[i++];
        i = text.length - 1;
        while (normalizeSpaces(text[i]) === ' ') spacesAtEnd += text[i--];
        if (spacesAtStart) {
            /**
             * @type {Node}
             */
            const textNode = document.createTextNode(spacesAtStart);
            element.before(textNode);
        }
        if (spacesAtEnd) {
            /**
             * @type {Node}
             */
            const textNode = document.createTextNode(spacesAtEnd);
            element.after(textNode);
        }
        recursively(element);
    }

    function recursively(element) {
        if (element.nodeType === Node.TEXT_NODE) {
            element.textContent = normalizeSpaces(
                element.textContent,
            ).replaceAll(' ', '');
        } else {
            for (let child of element.childNodes) {
                recursively(child);
            }
        }
    }
}

/**
 *
 * @param editor {EditorCustom}
 * @param textCase {TextCaseEnum}
 */
export function modifyTextCase(editor, textCase) {
    const pagesInvolved = getPagesInvolved(getSelectedNodes(editor));

    if (pagesInvolved.length > 1) {
        editor.notificationManager.open({
            // I18N
            text: 'Não é possível aplicar a formatação em mais de uma página',
            type: 'warning',
            timeout: 5000,
        });
        return;
    }

    editor.undoManager.transact(() => {
        const selection = getTinyMCESelectedHTML(editor);
        const modifiedSelection = modifyTextCaseFn(selection, textCase);
        editor.selection.setContent(modifiedSelection);
    });
}

/**
 * @param nodes {(HTMLElement | Node)[]}
 */
export function sanitizeFormattingElements(nodes) {
    for (const node of nodes) {
        if (['SUB', 'SUP'].includes(node.tagName)) {
            for (const attr of node.getAttributeNames()) {
                node.removeAttribute(attr);
            }
        }
    }
}

/**
 * @param editor {EditorCustom}
 * @param contextType {import('CoreModule').ContextType}
 */
export function applyContextInSelection(editor, contextType) {
    let content = editor.selection.getContent();
    let empty = false;

    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = content;
    removeNonPrintableChars(tmpDiv);

    if (tmpDiv.textContent.trim() === '') {
        content = '&#xFEFF';
        empty = true;
    } else {
        content = tmpDiv.innerHTML;
    }

    editor.selection.setContent(
        (empty
            ? '<span id="_mce_caret" data-mce-bogus="1" data-mce-type="format-caret">'
            : '') +
            `<editor-element type='${contextType}'>${content}</editor-element>` +
            (empty ? '</span>' : ''),
    );

    if (!empty) {
        editor.fire('FormatApply');
    }

    /**
     * @type {PageDataChangedEvent}
     */
    const pageDataChangedEvent = {
        caretPosition: getCaretPosition(editor),
    };
    editor.fire('pageDataChanged', pageDataChangedEvent);
}

/**
 * @param editor {EditorCustom}
 * @param contextType {import('CoreModule').ContextType}
 */
export function toggleContext(editor, contextType) {
    const selectedNode = editor.selection.getNode();
    const editorElement = getClosestEditorElement(selectedNode);
    if (editorElement?.getAttribute('type') === contextType) {
        // // remove context
        // const fragment = document.createDocumentFragment();
        // for (const child of [...editorElement.childNodes]) {
        //     fragment.appendChild(child);
        // }
        // editorElement.replaceWith(fragment);
        // editor.execCommand('mceToggleFormat', true, contextType);
    } else {
        const editorElement = document.createElement('editor-element');
        editorElement.setAttribute('type', contextType);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return;
        }
        editor.undoManager.transact(() => {
            applyContextInSelection(editor, contextType);
        });
    }
}
