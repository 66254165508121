/* eslint-disable quotes */
// noinspection NonAsciiCharacters

/**
 * @type {Set<string>}
 */
export const VALID_CHAR_SET = new Set([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',
    ' ',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'ö',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'ç',
    'é',
    'á',
    'è',
    'ú',
    'â',
    'ê',
    'ì',
    'ô',
    'ù',
    'à',
    'ï',
    'ü',
    'õ',
    'í',
    'ã',
    'ó',
    'ÿ',
    'ý',
    ',',
    '.',
    "'",
    '’',
    '‘',
    ';',
    ':',
    '!',
    '?',
    '-',
    '"',
    '“',
    '”',
    '(',
    ')',
    '[',
    ']',
    '*',
    '$',
    '…',
    '—',
    'º',
    'ª',
    '/',
    '\\',
    '_',
    '{',
    '}',
    '●',
    '•',
    '‣',
    '∙',
    '◆',
    '○',
    '⚫',
    '⚪',
    '◦',
    '▪',
    '▫',
    '■',
    '□',
    '▢',
    '@',
    '%',
    '¶',
    '©',
    '®',
    '÷',
    '<',
    '>',
    '″',
    '§',
    '°',
    '′',
    '¥',
    '£',
    '€',
    '#',
    '»',
    '›',
    '«',
    '‹',
    '–',
    '|',
    '=',
    '`',
    '~',
    '&',
    '‰',
    '+',
    '─',
    '←',
    '→',
    '↔',
    '✦',
    '†',
    '♀',
    '♂',
    '⚧',
    '✓',
    '×',
    '¬',
    '^',
    '≠',
    '≡',
    '∆',
    'µ',
    '±',
    '∈',
    '≅',
    'Ω',
    'λ',
    'Λ',
    'θ',
    'Θ',
    'α',
    'Α',
    'β',
    'Β',
    'γ',
    'Γ',
    'δ',
    'Δ',
    'ε',
    'Ε',
    'ζ',
    'Ζ',
    'η',
    'Η',
    'ι',
    'Ι',
    'κ',
    'Κ',
    'μ',
    'Μ',
    'ν',
    'Ν',
    'ξ',
    'Ξ',
    'ο',
    'Ο',
    'π',
    'Π',
    'ρ',
    'Ρ',
    'σ',
    'Σ',
    'τ',
    'Τ',
]);

/**
 * @type {Object<string,  number|number[]>}
 */
export const CHAR_MAP = {
    1: 0x2801,
    2: 0x2803,
    3: 0x2809,
    4: 0x2819,
    5: 0x2811,
    6: 0x280b,
    7: 0x281b,
    8: 0x2813,
    9: 0x280a,
    0: 0x281a,
    ' ': 0x2800,
    a: 0x2801,
    b: 0x2803,
    c: 0x2809,
    d: 0x2819,
    e: 0x2811,
    f: 0x280b,
    g: 0x281b,
    h: 0x2813,
    i: 0x280a,
    j: 0x281a,
    k: 0x2805,
    l: 0x2807,
    m: 0x280d,
    n: 0x281d,
    o: 0x2815,
    ö: 0x2815,
    p: 0x280f,
    q: 0x281f,
    r: 0x2817,
    s: 0x280e,
    t: 0x281e,
    u: 0x2825,
    v: 0x2827,
    w: 0x283a,
    x: 0x282d,
    y: 0x283d,
    z: 0x2835,
    ç: 0x282f,
    é: 0x283f,
    á: 0x2837,
    è: 0x282e,
    ú: 0x283e,
    â: 0x2821,
    ê: 0x2823,
    ì: 0x2829,
    ô: 0x2839,
    ù: 0x2831,
    à: 0x282b,
    ï: 0x283b,
    ü: 0x2833,
    õ: 0x282a,
    í: 0x280c,
    ã: 0x281c,
    ó: 0x282c,
    ÿ: 0x2832,
    ý: 0x2820,
    ',': 0x2802,
    '.': 0x2804,
    ';': 0x2806,
    ':': 0x2812,
    '!': 0x2816,
    '?': 0x2822,
    '-': 0x2824,
    '"': 0x2826,
    '“': 0x2826,
    '”': 0x2826,
    '*': 0x2814,
    $: 0x2830,
    º: 0x2815,
    ª: 0x2801,
    _: 0x2838,
    '{': 0x2828,
    '}': 0x2834,
    '@': 0x2831,
    '¶': 0x2832,
    '÷': 0x2832,
    '<': 0x282a,
    '>': 0x2815,
    '″': 0x2820,
    '°': 0x2834,
    '′': 0x2833,
    '¥': 0x283d,
    '£': 0x283e,
    '#': 0x283c,
    '|': 0x2838,
    '=': 0x2836,
    '`': 0x2820,
    '~': 0x2810,
    '&': 0x282f,
    '+': 0x2816,
    '─': 0x2824,
    '¬': 0x2818,
    '^': 0x2808,
    '(': 0x2823,
    ')': 0x281c,
    '[': 0x2837,
    ']': 0x283e,
    '—': 0x2832,
    '×': 0x2826,
    '/': 0x2832,
    "'": 0x2820,
    '’': 0x2820,
    // The characters above are used for special marks, such as numbers, uppercase letters, lowercase letters, etc.
    // They are used to differentiate regular characters from those with special meanings in Braille internally.
    '┘': 0x283c, // same as #
    '│': 0x2828, // same as {
    '◄': 0x2810, // same as ~
    '█': 0x2814, // same as *
};

/**
 * @enum {string}
 */
export const MARK_CHAR = {
    NUMBER: '┘',
    UPPER_CASE: '│',
    RETURNER: '◄',
    BOLD: '█',
    RECOIL_BLOCK: '╞', // will never be rendered, used on paragraph break routines
    MATH_BLOCK: '╪', // will never be rendered, used on paragraph break routines
    COMPUTER_RELATED_BLOCK: '▒', // will never be rendered, used on paragraph break routines
    RAW_DATA: '░', // will never be rendered, used on paragraph break routines
    EMPTY_CHAR: '▓',
};

export const MARK_CHAR_SET = new Set(Object.values(MARK_CHAR));

export const MARK_CHAR_EMPTY_SET = new Set([
    MARK_CHAR.RECOIL_BLOCK,
    MARK_CHAR.MATH_BLOCK,
    MARK_CHAR.COMPUTER_RELATED_BLOCK,
    MARK_CHAR.RAW_DATA,
]);
