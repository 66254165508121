import { InkFontTypeEnum } from 'plataforma-braille-common';
import { CORE_LOGGER_ID, isDebugEnabled } from './CoreModule';
import { getBrailleView } from './PageManipulation';
import {
    CHAR_MAP,
    MARK_CHAR_EMPTY_SET,
} from '../../../../conversion/braille/CharMap';

import { getBrailleDocument } from './EditorUtil';
import {
    backPropagateBreaksToElement,
    breakParagraphToFit,
    convertElementToBraille,
    mergeSiblingElements,
    ParagraphBreakType,
    removeParagraphBreaks,
} from '../../../../conversion/braille/HtmlToBraille';

/**
 * @typedef {object} BrailleDataChangedEvent
 * @property {HTMLElement | null | undefined} page
 */

/**
 * @param page {HTMLElement}
 * @param brailleView {HTMLElement | null}
 */
export function appendBrailleView(page, brailleView) {
    if (brailleView) {
        page.append(brailleView);
    }
}

/**
 * @returns {boolean}
 */
export function isDebugBrailleEnabled() {
    return process.env.REACT_APP_EDITOR_DEBUG_BRAILLE === 'true';
}

/**
 * @param container {HTMLElement}
 */
export function setCustomParagraphBreaks(container) {
    const paragraphBreaks = [];
    for (const type of Object.values(ParagraphBreakType)) {
        paragraphBreaks.push(
            ...container.querySelectorAll(`editor-element[type="${type}"]`),
        );
    }
    for (const paragraphBreak of paragraphBreaks) {
        paragraphBreak.setAttribute('data-custom-paragraph-break', true);
    }
}

/**
 * @param brailleRows {string[]}
 * @param brailleGrid {HTMLElement}
 * @param brailleCellColCount {number}
 * @param brailleCellRowCount {number}
 * @param warn {function | undefined}
 */
export function showBrailleInGrid(
    brailleRows,
    brailleGrid,
    { brailleCellColCount, brailleCellRowCount },
    warn = console.warn,
) {
    brailleGrid.innerHTML = '';
    for (let [rowIdx, row] of brailleRows.entries()) {
        if (rowIdx >= brailleCellRowCount) {
            warn(
                `Braille roll do not fit in grid. Max expected rows: ${brailleCellRowCount}, found: ${brailleRows.length}`,
            );
            break;
        }
        let cellCount = 0;
        for (let cellIdx = 0; cellIdx < row.length; cellIdx++) {
            const cell = row[cellIdx];
            if (cellIdx >= brailleCellColCount) {
                warn(
                    `Braille cell do not fit in grid. Max expected columns: ${brailleCellColCount}, found: ${row.length}`,
                );
                break;
            }
            const cellContainer = document.createElement('div');
            let brailleCell = CHAR_MAP[cell];
            if (brailleCell == null) {
                // some block mark, like recoil start or end
                if (MARK_CHAR_EMPTY_SET.has(cell)) {
                    continue;
                }
                brailleCell = CHAR_MAP[' '];
                warn(`Unsupported char: ${cell}`);
            }

            if (isDebugBrailleEnabled()) {
                cellContainer.innerText = cell;
            } else {
                cellContainer.innerText = String.fromCharCode(brailleCell);
            }
            brailleGrid.appendChild(cellContainer);
            cellCount++;
        }
        for (; cellCount < brailleCellColCount; cellCount++) {
            const cellContainer = document.createElement('div');
            cellContainer.innerText = String.fromCharCode(CHAR_MAP[' ']);
            brailleGrid.appendChild(cellContainer);
        }
    }
}

/**
 * @param brailleData {string} Braille data generated by Braille Facil
 * @param brailleDocument {BrailleDocument}
 * @return {{breaks: ParagraphBreak[], paragraphs: string[]}}
 */
export function getBrailleParagraphs(brailleData, brailleDocument) {
    /**
     * @type {ParagraphBreak[]}
     */
    const allBreaks = [];
    const paragraphs = brailleData.split('\r\n');
    /**
     * @type {string[]}
     */
    let adjustedParagraphs = [];
    for (const [i, paragraph] of paragraphs.entries()) {
        let { resultingLines, breaks } = breakParagraphToFit(
            paragraph,
            brailleDocument,
        );
        breaks = breaks.map((brk) => {
            brk.paragraph = i;
            return brk;
        });
        allBreaks.push(...breaks);
        adjustedParagraphs.push(...resultingLines);
    }
    for (let i = adjustedParagraphs.length - 1; i >= 0; i--) {
        if (!adjustedParagraphs[i]) {
            adjustedParagraphs.splice(i, 1);
        } else {
            break;
        }
    }
    return { breaks: allBreaks, paragraphs: adjustedParagraphs };
}

export class BrailleView {
    /**
     * @param editor {EditorCustom}
     */
    constructor(editor) {
        this.editor = editor;
    }

    debug(...data) {
        if (isDebugEnabled()) {
            console.debug(CORE_LOGGER_ID, ...data);
        }
    }

    warn(...data) {
        console.warn(CORE_LOGGER_ID, ...data);
    }

    error(...data) {
        console.error(CORE_LOGGER_ID, ...data);
    }

    /**
     * @return {HTMLElement}
     */
    createBrailleView() {
        const brailleView = this.editor.dom.create('editor-braille-view');
        brailleView.setAttribute('contentEditable', 'false');
        const braillePage = this.editor.dom.create('editor-braille-page');
        const brailleGrid = this.editor.dom.create('editor-braille-grid');
        brailleView.appendChild(braillePage);
        braillePage.appendChild(brailleGrid);
        return brailleView;
    }

    /**
     * @param braille {string[]}
     * @param brailleView {HTMLElement}
     * @param brailleDocument {BrailleDocument}
     */
    updateBrailleView(braille, brailleView, brailleDocument) {
        const {
            brailleCellRowCount,
            brailleCellColCount,
            braillePageMarginLeft,
            braillePageMarginRight,
            braillePageMarginTop,
            braillePageMarginBottom,
            pageHeight,
            pageWidth,
            pageMeasure,
        } = brailleDocument;

        let pageMeasureStr = pageMeasure?.toLowerCase();

        const braillePage = brailleView?.querySelector('editor-braille-page');
        const brailleGrid = brailleView?.querySelector('editor-braille-grid');
        braillePage.style.paddingLeft = `${braillePageMarginLeft}mm`;
        braillePage.style.paddingRight = `${braillePageMarginRight}mm`;
        braillePage.style.paddingTop = `${braillePageMarginTop}mm`;
        braillePage.style.paddingBottom = `${braillePageMarginBottom}mm`;
        braillePage.style.height = `${pageHeight}${pageMeasureStr}`;
        braillePage.style.marginTop = `-${pageHeight}${pageMeasureStr}`;
        braillePage.style.width = `${pageWidth}${pageMeasureStr}`;

        brailleGrid.style.gridTemplateRows = `repeat(${brailleCellRowCount}, 1fr)`;
        brailleGrid.style.gridTemplateColumns = `repeat(${brailleCellColCount}, 1fr)`;

        showBrailleInGrid(
            braille,
            brailleGrid,
            {
                brailleCellColCount,
                brailleCellRowCount,
            },
            this.warn,
        );
    }

    /**
     * @typedef {object} ParagraphChange
     * @property {number} paragraph
     * @property {ParagraphBreak[]} breaks
     */

    /**
     * @param page {HTMLElement | Node}
     */
    updatePage(page) {
        const brailleDocument = getBrailleDocument(this.editor);
        const {
            inkPageMarginTop,
            inkPageMarginRight,
            inkPageMarginLeft,
            inkPageMarginBottom,
            pageHeight,
            pageWidth,
            pageMeasure,
            inkFontType,
            inkPageLineHeight,
            inkFontSize,
        } = brailleDocument;

        let pageMeasureStr = pageMeasure?.toLowerCase();

        let brailleView = getBrailleView(page);

        page.style.removeProperty('margin-right');
        page.style.removeProperty('min-height');
        page.style.removeProperty('font-family');
        page.style.removeProperty('line-height');
        page.style.paddingLeft = `${inkPageMarginLeft}mm`;
        page.style.paddingRight = `${inkPageMarginRight}mm`;
        page.style.paddingTop = `${inkPageMarginTop}mm`;
        page.style.paddingBottom = `${inkPageMarginBottom}mm`;
        page.style.height = `${pageHeight}${pageMeasureStr}`;
        page.style.width = `${pageWidth}${pageMeasureStr}`;
        page.style.lineHeight = `${inkPageLineHeight}px`;
        const pageMargin = '30px'; // comes from css
        page.style.marginRight = `calc(${pageWidth}${pageMeasureStr} + ${pageMargin} * 2)`;
        let fontWeight;
        switch (inkFontType) {
            default:
                fontWeight = 'normal';
                break;
            case InkFontTypeEnum.DEJAVU_SANS_BOLD:
                fontWeight = 'bold';
                break;
        }
        page.style.fontSize = `${inkFontSize}px`;
        page.style.fontWeight = fontWeight;

        if (brailleView) {
            brailleView.remove();
        } else {
            brailleView = this.createBrailleView();
        }

        const brailleData = convertElementToBraille(
            page,
            this.editor.custom.coreModule.editorElements,
            brailleDocument,
        );

        removeParagraphBreaks(page);
        mergeSiblingElements(page);

        const brailleParagraphs = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );

        // braille view is removed in back propagation
        appendBrailleView(page, brailleView);

        backPropagateBreaksToElement(page, brailleParagraphs.breaks);

        /**
         * @type {BrailleDataChangedEvent}
         */
        const brailleDataChangedEvent = {
            page,
        };
        this.editor.fire('brailleDataChanged', brailleDataChangedEvent);

        this.updateBrailleView(
            brailleParagraphs.paragraphs,
            brailleView,
            brailleDocument,
        );
    }

    /**
     * @param page {HTMLElement | null}
     * @param update {boolean}
     */
    showBrailleView(page, update = false) {
        let brailleView = getBrailleView(page);
        if (brailleView && !update) {
            return;
        }
        if (brailleView) {
            brailleView.remove();
        } else {
            brailleView = this.createBrailleView();
        }

        const brailleDocument = getBrailleDocument(this.editor);
        const brailleData = convertElementToBraille(
            page,
            this.editor.custom.coreModule.editorElements,
            brailleDocument,
        );
        const { paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );
        this.updateBrailleView(paragraphs, brailleView, brailleDocument);

        appendBrailleView(page, brailleView);
    }
}

/**
 *
 * @param node {HTMLElement | ChildNode}
 * @returns {boolean}
 */
export function isEditorBrailleView(node) {
    return node?.tagName === 'EDITOR-BRAILLE-VIEW';
}
