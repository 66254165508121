/**
 * @enum {number}
 */
export const BrailleFacilConversionFlag = {
    INSIDE_PAGE: 0,
    INSIDE_HIGHLIGHT: 1,
    INSIDE_UNDERLINE: 2,
    CONTEXT_COMPUTER_RELATED: 3,
    CONTEXT_MATH: 4,
    CONTEXT_IMAGE: 5,
    CONTEXT_CATALOG: 6,
    RAW_BRAILLE_OUTPUT: 7,
};
