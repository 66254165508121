import { UrlRegExp } from '../../util/UrlRegExp';
import {
    getParagraphTextAfterNode,
    getParagraphTextUntilNode,
    isEditorElement,
    mergeSiblingEqualsNodes,
    removeInnerSpaces,
} from '../../edit-document/editor-mods/modules/core/EditorUtil';
import { BulletChars } from '../../util/BulletChars';
import { removeEmptyHighlight } from './Highlight';
import { extractComputerRelatedContext } from './ComputerRelatedContext';
import { replaceBracketsAndParentheses } from './BracketsAndParentheses';
import { removeNonPrintableChars } from '../../edit-document/editor-mods/modules/core/ShowNonPrintableChars';
import { BrailleFacilConversionFlag } from './BrailleFacilConversionFlag';
import { MARK_CHAR } from '../braille/CharMap';
import { removeParagraphBreaks } from '../braille/HtmlToBraille';
import { markLines } from '../../edit-document/editor-mods/modules/core/EditorElements';
import { normalizeSpaces } from '../../util/TextUtil';

/**
 * @param style {string}
 * @returns {boolean}
 */
export function isStyleUnderline(style) {
    return !!(style?.match && style.match(/text-decoration\s*:\s*underline/gi));
}

/**
 * @param txt {string}
 * @param replaceBy {string | undefined}
 * @return {string}
 */
export function prepareEditorElementImageText(txt, replaceBy = '"') {
    // replace 'x' to '"' in a measurement context inside an image (#44866)
    return txt.replace(
        /([\d+,.]+\s*\w*\s*)(x)(\s*[\d+,.]+\w*)(\s*)/gi,
        (match, g1, g2, g3, g4) => {
            if (g1.match(/\D*\d( *)$/gm)) g1 = g1.trimEnd();
            if (g3.match(/^( *)\d+/gm)) g3 = g3.trimStart();
            return `${g1}${replaceBy}${g3}${g4}`;
        },
    );
}

/**
 * @param txt {string}
 * @param convertSymbolsToText {boolean|null}
 * @returns {string}
 */
export function prepareMathContext(txt, convertSymbolsToText = true) {
    let multiplicationSymbol = '"';
    if (convertSymbolsToText) {
        // replace '″' to 'ý"' (line 44 of spec sheet)
        txt = txt.replace(/″/g, 'ý"');
        // replace '—' to 'ÿ' (#38989 comment 22)
        txt = txt.replace(/—/g, 'ÿ');
    } else {
        multiplicationSymbol = '×';
    }

    // replace 'x' to '"' (line 41 of spec sheet)
    let regExp = /(^| *)([\d\\[\](){}]+ *)([x*])( *[\d\\[\](){}]+)($| *)/g;
    while (txt.match(regExp))
        txt = txt.replace(regExp, `$1$2${multiplicationSymbol}$4$5`);
    // replace 'x' to '"' (line 41 of spec sheet)
    regExp = /(^| *)([a-z\\[\](){}]+ +)([x*])( +[a-z\\[\](){}]+)($| *)/gi;
    while (txt.match(regExp))
        txt = txt.replace(regExp, `$1$2${multiplicationSymbol}$4$5`);
    // replace '÷' to 'ÿ' (line 42 of spec sheet)
    txt = txt.replace(/\//g, 'ÿ');

    let rows = txt.split('\r\n');
    return rows
        .map(function (row) {
            return removeInnerSpaces(row);
        })
        .join('\r\n');
}

/**
 * @param text {string}
 * @return {string}
 */
export function applyHighlight(text) {
    return text.replace(/( *)(.+?)( *)$/gm, (match, g1, g2, g3) => {
        if (g2.trim().length) {
            return `${g1}${MARK_CHAR.BOLD}${g2}${MARK_CHAR.BOLD}${g3}`;
        } else {
            return match;
        }
    });
}

/**
 * @param text {string}
 * @param config {undefined | {bulletChar: string | undefined, hyphenChar: string | undefined}}
 * @return {string}
 */
export function parseHighlightSpecialCases(text, config = {}) {
    if (!config.bulletChar) {
        config.bulletChar = 'õo';
    }
    if (!config.hyphenChar) {
        config.hyphenChar = '--';
    }

    // fix: https://sgm.codebit.com.br/manutencao/36465
    // the bullet comes already converted in braille fácil
    if (text.match(new RegExp(`\\s*${config.bulletChar}\\s*`, 'g'))) {
        text = text.replace(
            new RegExp(`( *)(\\*)(${config.bulletChar} *)(.*?)(\\*)`, 'g'),
            '$1$3*$4$5',
        );
    }
    // remove hyphen from highlight (#45186)
    if (text.match(new RegExp(`\\s*${config.hyphenChar}\\s*`, 'g'))) {
        text = text.replace(
            new RegExp(`( *)(\\*)(${config.hyphenChar} *)(.*?)(\\*)`, 'g'),
            '$1$3*$4$5',
        );
    }
    return text;
}

/**
 * Use grouping in numbers bigger than 9999 (extended rules)
 *
 * @param txt {string}
 * @returns {string}
 */
export function addGroupInNumbersBiggerThan4Digits(txt) {
    return txt.replace(/([\d.,]{5,})/g, (match, number) => {
        number = number.replaceAll('.', '');
        const chunks = number.split(',');
        const intChunk = chunks[0].padStart(2, '0');
        if (intChunk.length < 5) return match;
        const decimalChunk = chunks[1] != null;
        const group = intChunk.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return group + (decimalChunk ? ',' + chunks[1] : '');
    });
}

/**
 * @param txt {string}
 * @param flags {BrailleFacilConversionFlag[]}
 * @param conversionParams {ConversionParams}
 * @param editorElements {EditorElements}
 * @return {string}
 */
function processText(txt, flags, conversionParams, editorElements) {
    txt = txt.replace(/\n\r?/g, '');

    // const mathContext = flags.includes(ConversionFlags.CONTEXT_MATH);

    // source: https://sgm.codebit.com.br/manutencao/36460 (complements requested in #41508)
    if (!flags.includes(BrailleFacilConversionFlag.CONTEXT_CATALOG)) {
        // alternatives always in lower case (extended rules)
        txt = txt.replace(
            /^( *)([a-zA-ZÀ-ÿ])(\))(\s)(.*)/gm,
            (match, g1, g2, g3, g4, g5) => {
                return `${g1}${g2.toLowerCase()}${g3}${g4}${g5}`;
            },
        );
    }

    if (flags.includes(BrailleFacilConversionFlag.CONTEXT_IMAGE)) {
        txt = prepareEditorElementImageText(txt);
    }

    // the add of spaces or great changes in characters may impact and break
    // the back propagation of paragraphs breaks to editor
    if (!flags.includes(BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT)) {
        // replace 'nº' to 'n.o' (extended rules)
        txt = txt.replace(/(nº)/gi, (match, g1) => {
            if (g1.charAt(0) === g1.charAt(0).toUpperCase()) return 'N.o';
            return 'n.o';
        });

        // extra request at #39013, comment 5
        if (
            !flags.includes(
                BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED,
            ) &&
            !flags.includes(BrailleFacilConversionFlag.CONTEXT_CATALOG)
        ) {
            txt = addGroupInNumbersBiggerThan4Digits(txt);
        }
        // include space between numbers and letters in time (extended rules)
        // I18N
        txt = txt.replace(
            /(?<!\d)(\d{1,2})(\s*)(horas|h|minutos|min|m|segundos|seg|s)(?=\W|$)/gi,
            '$1 $3',
        );
        // remove spaces from coin (extended rules)
        txt = txt.replace(/(R\$)(\s*)([\d.,]+)/gm, '$1$3');
    }

    // replace '✓' to '?í' (extended rules)
    txt = txt.replace(/[✅✓☑✔]/g, '?í');
    // replace answer lines (extended rules)
    txt = txt.replace(/(_{3,})/g, () => {
        return '.....';
    });
    // replace a dash by two hyphens (line 16 of spec sheet)
    txt = txt.replace(/(^ *)(-)( +[A-Z]|$)/gim, '$1--$3');
    txt = txt.replace(/—/g, '--');
    // replace ''' to 'ý"' (lines 26 and 27 of spec sheet)
    txt = txt.replace(/(')([\p{L}\s]*)([\p{L}?!:.])(')/gu, 'ý"$2$3ý"');
    // replace double quotes to '$"' (lines 28 and 29 of spec sheet)
    // Fix: https://sgm.codebit.com.br/manutencao/36474
    txt = txt.replace(/““|””|ʻʻ|ʼʼ|''|""|``/g, '$"');

    // do first to not conflict bellow
    // source: https://sgm.codebit.com.br/manutencao/36456
    txt = txt.replace(
        new RegExp(`(<\\s*)${UrlRegExp}(\\s*>)`, 'gim'),
        (match, g1, g2) => {
            return g2.replaceAll(' ', '');
        },
    );
    // replace '÷' to 'ÿ' (line 42 of spec sheet)
    txt = txt.replace(/÷/g, 'ÿ');
    // replace 'x' to '"' (line 41 of spec sheet)
    txt = txt.replaceAll('×', '"');
    // replace '⋅' to '"' (#48834)
    txt = txt.replaceAll('⋅', '"');
    // do first to not conflict bellow
    // replace '•' to 'õo' (line 30 of spec sheet)
    txt = txt.replace(new RegExp(BulletChars.join('|'), 'g'), 'õo');
    // replace '■' to '_y' (line 31 of spec sheet)
    txt = txt.replace(/■|▪️|▫️|◼️|◻️|⬛️|⬜️|🟩|🟦️/g, '_y');
    // replace '#' to '#k' (line 33 of spec sheet)
    txt = txt.replace(/#/g, '#k');
    // replace '©' to '`(C`)' (line 34 of spec sheet)
    txt = txt.replace(/©/g, '`(C`)');
    // replace '®' to '`(R`)' (line 35 of spec sheet)
    txt = txt.replace(/®/g, '`(R`)');
    // replace '€' to '`(R`)' (line 37 of spec sheet)
    txt = txt.replace(/€/g, '^e');
    // replace '£' to '^l' (line 38 of spec sheet)
    txt = txt.replace(/£/g, '^l');
    // replace '¥' to '^y' (line 39 of spec sheet)
    txt = txt.replace(/¥/g, '^y');
    // replace ''' and '''' to 'ü' and 'üü' (lines 46 and 47 of spec sheet)
    txt = txt.replace(/(ˆ|\s)(\d+)(')(\s*)(\d+)('')($|\s)/g, '$1$2ü$4$5üü$7');
    // replace '≠' to '¬=' (#47376)
    txt = txt.replace(/≠/g, '¬=');
    // replace '≡' to '==' (#47376)
    txt = txt.replace(/≡/g, '==');
    // replace '∆' to '¬d' (#47376)
    txt = txt.replace(/∆/g, '¬d');
    // replace 'µ' to '^m' (#47376)
    txt = txt.replace(/µ/g, '^m');
    // replace '±' to '!:-' (#47376)
    txt = txt.replace(/±/g, '!:-');
    // replace '∈' to 'ê,' (#47376)
    txt = txt.replace(/∈/g, 'ê,');
    // replace '≅' to '^=,' (#47376)
    txt = txt.replace(/≅/g, '^=');
    // replace 'Ω' to '¬w,' (#47376)
    txt = txt.replace(/Ω/g, '¬w');
    // replace 'λ' to '^l,' (#47376)
    txt = txt.replace(/λ/g, '^l');
    // replace 'θ' to '^l,' (#58628)
    txt = txt.replace(/θ/g, '^ô'); // lowercase theta
    // replace 'Θ' to '^l,' (#58628)
    txt = txt.replace(/Θ/g, '¬ô'); // uppercase theta
    // replace 'α' to '^a,' (#58628)
    txt = txt.replace(/α/g, '^a'); // lowercase alpha
    // replace 'Α' to '¬a,' (#58628)
    txt = txt.replace(/Α/g, '¬a'); // uppercase alpha
    // replace 'β' to '^b,' (#58628)
    txt = txt.replace(/β/g, '^b'); // lowercase beta
    // replace 'Β' to '¬b,' (#58628)
    txt = txt.replace(/Β/g, '¬b'); // uppercase beta
    // replace 'γ' to '^g,' (#58628)
    txt = txt.replace(/γ/g, '^g'); // lowercase gamma
    // replace 'Γ' to '¬g,' (#58628)
    txt = txt.replace(/Γ/g, '¬g'); // uppercase gamma
    // replace 'δ' to '^d,' (#58628)
    txt = txt.replace(/δ/g, '^d'); // lowercase delta
    // replace 'Δ' to '¬d,' (#58628)
    txt = txt.replace(/Δ/g, '¬d'); // uppercase delta
    // replace 'ε' to '^e,' (#58628)
    txt = txt.replace(/ε/g, '^e'); // lowercase epsilon
    // replace 'Ε' to '¬e,' (#58628)
    txt = txt.replace(/Ε/g, '¬e'); // uppercase epsilon
    // replace 'ζ' to '^z,' (#58628)
    txt = txt.replace(/ζ/g, '^z'); // lowercase zeta
    // replace 'Ζ' to '¬z,' (#58628)
    txt = txt.replace(/Ζ/g, '¬z'); // uppercase zeta
    //replace 'η' to '^û,' (#58628)
    txt = txt.replace(/η/g, '^û'); // lowercase eta
    // replace 'Η' to '¬û,' (#58628)
    txt = txt.replace(/Η/g, '¬û'); // uppercase eta
    // replace 'ι' to '^i,' (#58628)
    txt = txt.replace(/ι/g, '^i'); // lowercase iota
    // replace 'Ι' to '¬i,' (#58628)
    txt = txt.replace(/Ι/g, '¬i'); // uppercase iota
    // replace 'κ' to '^k,' (#58628)
    txt = txt.replace(/κ/g, '^k'); // lowercase kappa
    // replace 'Κ' to '¬k,' (#58628)
    txt = txt.replace(/Κ/g, '¬k'); // uppercase kappa
    // replace 'Λ' to '¬l,' (#58628)
    txt = txt.replace(/Λ/g, '¬l'); // uppercase lambda
    // replace 'μ' to '^m,' (#58628)
    txt = txt.replace(/μ/g, '^m'); // lowercase mu
    // replace 'Μ' to '¬m,' (#58628)
    txt = txt.replace(/Μ/g, '¬m'); // uppercase mu
    // replace 'ν' to '^n,' (#58628)
    txt = txt.replace(/ν/g, '^n'); // lowercase nu
    // replace 'Ν' to '¬n,' (#58628)
    txt = txt.replace(/Ν/g, '¬n'); // uppercase nu
    // replace 'ξ' to '^x,' (#58628)
    txt = txt.replace(/ξ/g, '^x'); // lowercase xi
    // replace 'Ξ' to '¬x,' (#58628)
    txt = txt.replace(/Ξ/g, '¬x'); // uppercase xi
    // replace 'ο' to '^o,' (#58628)
    txt = txt.replace(/ο/g, '^o'); // lowercase omicron
    // replace 'Ο' to '¬o,' (#58628)
    txt = txt.replace(/Ο/g, '¬o'); // uppercase omicron
    // replace 'π' to '^p,' (#58628)
    txt = txt.replace(/π/g, '^p'); // lowercase pi
    // replace 'Π' to '¬p,' (#58628)
    txt = txt.replace(/Π/g, '¬p'); // uppercase pi
    // replace 'ρ' to '^r,' (#58628)
    txt = txt.replace(/ρ/g, '^r'); // lowercase rho
    // replace 'Ρ' to '¬r,' (#58628)
    txt = txt.replace(/Ρ/g, '¬r'); // uppercase rho
    // replace 'σ' to '^s,' (#58628)
    txt = txt.replace(/σ/g, '^s'); // lowercase sigma
    // replace 'Σ' to '¬s,' (#58628)
    txt = txt.replace(/Σ/g, '¬s'); // uppercase sigma
    // replace 'τ' to '^t,' (#58628)
    txt = txt.replace(/τ/g, '^t'); // lowercase tau
    // replace 'Τ' to '¬t,' (#58628)
    txt = txt.replace(/Τ/g, '¬t'); // uppercase tau
    // replace 'υ' to '^u,' (#58628)
    txt = txt.replace(/υ/g, '^u'); // lowercase upsilon
    // replace 'Υ' to '¬u,' (#58628)
    txt = txt.replace(/Υ/g, '¬u'); // uppercase upsilon
    // replace 'φ' to '^f,' (#58628)
    txt = txt.replace(/φ/g, '^f'); // lowercase phi
    // replace 'Φ' to '¬f,' (#58628)
    txt = txt.replace(/Φ/g, '¬f'); // uppercase phi
    // replace 'χ' to '^ç,' (#58628)
    txt = txt.replace(/χ/g, '^ç'); // lowercase chi
    // replace 'Χ' to '¬ç,' (#58628)
    txt = txt.replace(/Χ/g, '¬ç'); // uppercase chi
    // replace 'ψ' to '^y,' (#58628)
    txt = txt.replace(/ψ/g, '^y'); // lowercase psi
    // replace 'Ψ' to '¬y,' (#58628)
    txt = txt.replace(/Ψ/g, '¬y'); // uppercase psi
    // replace 'ω' to '^w,' (#58628)
    txt = txt.replace(/ω/g, '^w'); // lowercase omega
    // replace 'Ω' to '¬w,' (#58628)
    txt = txt.replace(/Ω/g, '¬w'); // uppercase omega
    // replace '–' to '-' (#51157)
    txt = txt.replace(/–/g, '-');
    // source: https://sgm.codebit.com.br/manutencao/36459

    if (
        !flags.includes(BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED) &&
        !flags.includes(BrailleFacilConversionFlag.CONTEXT_CATALOG)
    ) {
        // extra request at #39013, comment 5
        txt = txt.replace(
            /(\w*?)( *)((\d+([.,]\d)*)+)( *)(\w*)/gi,
            (matches, g1, g2, g3, g4, g5, g6, g7) => {
                g1 = g1 ?? '';
                g2 = g2 ?? '';
                g3 = g3 ?? '';
                g6 = g6 ?? '';
                g7 = g7 ?? '';
                const number = parseInt(
                    g3.replaceAll('.', '').replaceAll(',', '.'),
                );
                if (
                    number <= 9999 &&
                    g7.toLowerCase() !== 'lei' &&
                    g1.toLowerCase() !== 'lei'
                ) {
                    g3 = g3.replaceAll('.', '');
                }
                return g1 + g2 + g3 + g6 + g7;
            },
        );
    }

    if (flags.includes(BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED)) {
        txt = extractComputerRelatedContext(
            txt,
            conversionParams,
            editorElements,
            flags,
        );
    }

    // replace NBSP to space
    txt = normalizeSpaces(txt);

    return txt;
}

/**
 * @typedef {object} ConversionParams
 * @property {string} paragraphTextAfterNode
 * @property {string} paragraphTextUntilNode
 * @property {BrailleDocument} brailleDocument
 */

/**
 * @param node {Node | HTMLElement}
 * @param flags {BrailleFacilConversionFlag[]}
 * @param editorElements {EditorElements}
 * @param brailleDocument {BrailleDocument}
 * @return {string}
 */
export function extractRecursively(
    node,
    flags,
    editorElements,
    brailleDocument,
) {
    if (!node) {
        return '';
    }
    const paragraphTextUntilNode = getParagraphTextUntilNode(node);
    const paragraphTextAfterNode = getParagraphTextAfterNode(node);
    /**
     * @type {ConversionParams}
     */
    const conversionParams = {
        paragraphTextUntilNode,
        paragraphTextAfterNode,
        brailleDocument,
    };

    const rootNode = !flags;
    if (rootNode) flags = [];
    // text
    if (node.nodeType === Node.TEXT_NODE) {
        if (node.textContent === '\n') return '';
        return processText(
            node.textContent,
            flags,
            conversionParams,
            editorElements,
        );
    } else {
        let txt = '';
        let { tagName, childNodes } = node;
        if (!childNodes) childNodes = [];
        const style = node.attributes?.getNamedItem('style')?.value;

        if (tagName === 'EDITOR-PAGE') {
            const insidePage =
                flags.indexOf(BrailleFacilConversionFlag.INSIDE_PAGE) !== -1;
            if (!insidePage)
                flags = [...flags, BrailleFacilConversionFlag.INSIDE_PAGE];
            // page number at start (extended rules)
            // if (!insidePage) {
            //     const num = node.attributes?.getNamedItem('num')?.value;
            //     txt += `<${num}>\r\n`;
            // }
            for (const childNode of childNodes) {
                txt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            // if (!insidePage) {
            //     txt += '\r\n<P>\r\n';
            // }
        } else if (tagName === 'BR') {
            for (const childNode of childNodes) {
                txt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            txt += '\r\n';
        } else if (
            tagName === 'STRONG' ||
            tagName === 'EM' ||
            (isStyleUnderline(style) &&
                !flags.includes(
                    BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED,
                ))
        ) {
            const insideHighlight =
                flags.indexOf(BrailleFacilConversionFlag.INSIDE_HIGHLIGHT) !==
                -1;
            if (!insideHighlight)
                flags = [...flags, BrailleFacilConversionFlag.INSIDE_HIGHLIGHT];
            let newTxt = '';
            for (const childNode of childNodes) {
                newTxt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            if (!insideHighlight) {
                newTxt = applyHighlight(newTxt);
            }
            newTxt = parseHighlightSpecialCases(newTxt);

            return newTxt;
        } else if (tagName === 'SUP') {
            let content = '';
            for (const childNode of childNodes) {
                content += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            if (content.trim().length) {
                txt += 'â';
                txt += content;
            }
        } else if (tagName === 'SUB') {
            let content = '';
            for (const childNode of childNodes) {
                content += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            if (content.trim().length) {
                txt += 'í';
                txt += content;
            }
        } else if (isEditorElement(node)) {
            const type = node.getAttribute('type')?.toLowerCase();
            txt += extractEditorElement(
                node,
                type,
                editorElements,
                brailleDocument,
                flags,
            );
        } else if (
            isStyleUnderline(style) &&
            flags.includes(
                BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED,
            ) &&
            !flags.includes(BrailleFacilConversionFlag.INSIDE_UNDERLINE)
        ) {
            flags = [...flags, BrailleFacilConversionFlag.INSIDE_UNDERLINE];
            let newTxt = '';
            for (const childNode of childNodes) {
                newTxt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            newTxt = newTxt.replace(/( *)(.+?)( *)$/gm, '$1l-$2l:$3');
            txt += newTxt;
        } else {
            if (tagName !== 'DIV') {
                console.debug('No specific implementation to node.', tagName);
            }
            for (const childNode of childNodes) {
                txt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
        }
        return txt;
    }
}

/**
 * @param node {Node | HTMLElement}
 * @param type {string}
 * @param flags {BrailleFacilConversionFlag[]}
 * @param editorElements {EditorElements}
 * @param brailleDocument {BrailleDocument}
 * @return {string}
 */
function extractEditorElement(
    node,
    type,
    editorElements,
    brailleDocument,
    flags,
) {
    if (type === 'suppression') {
        let txt = '<F->\r\n';
        txt += extractRecursivelySuppression(node) + '\r\n';
        txt += '<F+>\r\n';
        return txt;
    } else if (type === 'math') {
        const alreadyInMathContext = flags.includes(
            BrailleFacilConversionFlag.CONTEXT_MATH,
        ); // recursion, may a element inside a element
        let txt = '';
        flags = [...flags, BrailleFacilConversionFlag.CONTEXT_MATH];
        const rawBrailleOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        for (const childNode of node.childNodes) {
            txt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        if (alreadyInMathContext) {
            return txt;
        }
        txt = prepareMathContext(txt);
        if (rawBrailleOutput && !alreadyInMathContext) {
            txt = markLines(txt, MARK_CHAR.MATH_BLOCK);
        }
        return txt;
    } else if (
        type === 'computer-related' &&
        !flags.includes(BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED)
    ) {
        let txt = '';
        const alreadyInComputerRelatedContext = flags.includes(
            BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED,
        ); // recursion, may a element inside a element
        flags = [...flags, BrailleFacilConversionFlag.CONTEXT_COMPUTER_RELATED];
        const rawBrailleOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        for (const childNode of node.childNodes) {
            txt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        if (rawBrailleOutput && !alreadyInComputerRelatedContext) {
            txt = txt
                .split('\r\n')
                .map((line) => {
                    return (
                        MARK_CHAR.COMPUTER_RELATED_BLOCK +
                        line +
                        MARK_CHAR.COMPUTER_RELATED_BLOCK
                    );
                })
                .join('\r\n');
        }
        return txt;
    } else if (
        type === 'catalog' &&
        !flags.includes(BrailleFacilConversionFlag.CONTEXT_CATALOG)
    ) {
        flags = [...flags, BrailleFacilConversionFlag.CONTEXT_CATALOG];
        let txt = '';
        for (const childNode of node.childNodes) {
            txt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        return txt;
    } else if (
        type === 'representation-line-break' ||
        type?.startsWith('paragraph-break')
    ) {
        return '';
    } else if (['representation-space', 'representation-nbsp'].includes(type)) {
        return ' ';
    } else if (type === 'revision-error') {
        let txt = '';
        for (const childNode of node.childNodes) {
            txt += extractRecursively(
                childNode,
                flags,
                editorElements,
                brailleDocument,
            );
        }
        return txt;
    } else {
        const txt = editorElements.convertToBraille(node, flags);
        if (txt) {
            return txt;
        } else {
            console.warn(`Editor element isn't knew: ${type}`);
            let txt = '';
            for (const childNode of node.childNodes) {
                txt += extractRecursively(
                    childNode,
                    flags,
                    editorElements,
                    brailleDocument,
                );
            }
            return txt;
        }
    }
}

function extractRecursivelySuppression(node) {
    if (node.nodeType === Node.TEXT_NODE) {
        return node.textContent;
    } else {
        let txt = '';
        let { tagName, childNodes } = node;
        if (tagName === 'BR') {
            for (const childNode of childNodes) {
                txt += extractRecursivelySuppression(childNode);
            }
            txt += '\r\n';
        } else {
            console.warn(
                'No specific implementation to node in suppression context.',
                tagName,
            );
            for (const childNode of childNodes) {
                txt += extractRecursivelySuppression(childNode);
            }
        }
        return txt;
    }
}

class HtmlToBrailleFacil {
    /**
     * @param nodes {Node[] | HTMLElement[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     */
    constructor(nodes, editorElements, brailleDocument) {
        this.nodes = nodes;
        this.editorElements = editorElements;
        this.brailleDocument = brailleDocument;
    }

    _postProcessTextNode(txt) {
        const lines = txt.split('\r\n');
        txt = '';
        for (let line of lines) {
            line = removeEmptyHighlight(line);

            txt += line + '\r\n';
        }
        return txt;
    }

    /**
     * @param txt {string}
     * @return {string}
     * @private
     */
    _processRecoil(txt) {
        // removes spaces before the tag and bold or other highlight (#48258)
        return txt.replace(
            /([█ ]*< *r\+ *>[█ ]*)([\s\S]*?)([█ ]*< *r- *>[█ ]*)/gi,
            (match, g1, g2) => {
                return `<R+>${g2}<R->`;
            },
        );
    }

    /**
     * @private
     * @param txt {string}
     * @return {string}
     */
    _postProcessFullText(txt) {
        // replace NBSP to space
        txt = normalizeSpaces(txt);
        // remove empty lines from the end of page
        txt = txt.replace(/(\s*<P>)/gim, '\r\n<P>');
        // remove empty page at the end
        txt = txt.replace(/(\s*<P>\s*)$/g, '');
        // keeps only one space after bullet (#45185)
        txt = txt.replace(new RegExp('(õo) +', 'g'), '$1 ');
        // let a single space after an image (#45187)
        txt = txt.replace(
            /(\S\s*)(^<R\+ *>\s*_y[^<]*<R- *>$)((\r\n)*)/gim,
            '$1\r\n$2\r\n\r\n',
        );
        // remove duplicated lines every time (#44866)
        txt = txt.replace(/(\r\n\r\n)((\r\n)+)/g, '$1');
        // replace curved apostrophes with straight ones (#50623)

        // eslint-disable-next-line
        txt = txt.replaceAll('’', "'");

        txt = replaceBracketsAndParentheses(txt);

        txt = this._processRecoil(txt);

        txt = txt.replace(/<F\+>\s*<F->\s*/gi, '');

        /**
         * @type {string}
         */
        const boldMark = MARK_CHAR.BOLD;
        txt = txt.replaceAll(boldMark, '*');

        return txt.trimEnd();
    }

    convertToBraille() {
        let fullTxt = '';

        for (const page of this.nodes) {
            // first level always be a page
            removeParagraphBreaks(page);
            removeNonPrintableChars(page);
            mergeSiblingEqualsNodes(page);
            let pageTxt = '';
            let accumulatedNormalNodes = [];

            let flags = [BrailleFacilConversionFlag.INSIDE_PAGE];

            const self = this;
            function dumpNormalNodes() {
                if (accumulatedNormalNodes.length) {
                    let txt = '';
                    for (const normalNode of accumulatedNormalNodes) {
                        txt += extractRecursively(
                            normalNode,
                            flags,
                            self.editorElements,
                            self.brailleDocument,
                        );
                    }
                    accumulatedNormalNodes = [];
                    pageTxt += self._postProcessTextNode(txt);
                }
            }

            for (const node of page.childNodes) {
                const nodeTag = node?.tagName;
                const nodeType = node?.getAttribute
                    ? node.getAttribute('type')?.toLowerCase()
                    : null;

                if (
                    nodeTag === 'EDITOR-ELEMENT' &&
                    nodeType === 'suppression'
                ) {
                    dumpNormalNodes();
                    pageTxt += extractRecursively(
                        node,
                        flags,
                        this.editorElements,
                        this.brailleDocument,
                    );
                } else {
                    accumulatedNormalNodes.push(node);
                }
            }
            dumpNormalNodes();

            pageTxt += '\r\n';
            fullTxt += pageTxt;
        }
        return this._postProcessFullText(fullTxt);
    }
}

export default HtmlToBrailleFacil;
