import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestTextNode,
    getSelectedParagraphs,
    isEditorElement,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
} from '../EditorElements';
import { getCaretPosition } from '../CaretPath';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { addIcon } from '../../menu/MenuModule';
import {
    EditorElementHeadingSize1,
    EditorElementHeadingSize12x,
    EditorElementHeadingSize2,
    EditorElementHeadingSize22x,
    EditorElementHeadingSize3,
    EditorElementHeadingSize32x,
    EditorElementHeadingSize4,
    EditorElementHeadingSize42x,
    EditorElementHeadingSize5,
    EditorElementHeadingSize52x,
} from '../../../../../components/images';
export const EDITOR_ELEMENT_HEADING_1 = 'EDITOR_ELEMENT_HEADING_1';
export const EDITOR_ELEMENT_HEADING_2 = 'EDITOR_ELEMENT_HEADING_2';
export const EDITOR_ELEMENT_HEADING_3 = 'EDITOR_ELEMENT_HEADING_3';
export const EDITOR_ELEMENT_HEADING_4 = 'EDITOR_ELEMENT_HEADING_4';
export const EDITOR_ELEMENT_HEADING_5 = 'EDITOR_ELEMENT_HEADING_5';

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement}
 * @return {undefined}
 */
export function focusOnCurrentEditorElementHeading(editor, element) {
    /**
     * @type {HTMLElement | null}
     */
    let fieldToFocus = null;
    switch (Number(element.getAttribute('data-heading-size'))) {
        case 1:
            fieldToFocus = element.querySelector('.second-value');
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            fieldToFocus = element.querySelector('.value');
            break;
    }
    const text = getClosestTextNode(fieldToFocus?.lastChild);
    if (text) {
        editor.selection.setCursorLocation(text, text.textContent.length);
    }
}

/**
 * @param editor {EditorCustom}
 * @returns {[{onAction: *, text: string, type: string, icon: string}]}
 */
export function getHeadingSubmenuItems(editor) {
    addIcon(
        editor,
        'customEditorHeading1',
        EditorElementHeadingSize1,
        EditorElementHeadingSize12x,
    );
    addIcon(
        editor,
        'customEditorHeading2',
        EditorElementHeadingSize2,
        EditorElementHeadingSize22x,
    );
    addIcon(
        editor,
        'customEditorHeading3',
        EditorElementHeadingSize3,
        EditorElementHeadingSize32x,
    );
    addIcon(
        editor,
        'customEditorHeading4',
        EditorElementHeadingSize4,
        EditorElementHeadingSize42x,
    );
    addIcon(
        editor,
        'customEditorHeading5',
        EditorElementHeadingSize5,
        EditorElementHeadingSize52x,
    );

    const { editorElements } = editor.custom.coreModule;
    return [
        {
            type: 'menuitem',
            // I18N
            text: 'Título 1',
            icon: 'customEditorHeading1',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_1);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 2',
            icon: 'customEditorHeading2',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_2);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 3',
            icon: 'customEditorHeading3',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_3);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 4',
            icon: 'customEditorHeading4',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_4);
            },
        },
        {
            type: 'menuitem',
            // I18N
            text: 'Título 5',
            icon: 'customEditorHeading5',
            onAction: function () {
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_HEADING_5);
            },
        },
    ];
}

/**
 * @param editor {EditorCustom}
 * @return {undefined}
 */
export function removeCurrentEditorElementHeading(editor) {
    const element = getClosestEditorElementHeading(editor.selection.getNode());
    editor.dom.remove(element);
    editor.focus();
}

/**
 * @param editor {EditorCustom | undefined | null}
 * @param headingSize {(1|2|3|4|5)}
 * @return {HTMLElement}
 */
export function createEditorElementHeading(editor = null, headingSize) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-heading';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'heading');
    editorElement.setAttribute('data-heading-size', headingSize.toString());
    editorElement.setAttribute('id', elementId);

    const containerDiv = document.createElement('div');
    containerDiv.setAttribute('class', 'container');
    containerDiv.setAttribute('contentEditable', 'false');

    const valueDiv = document.createElement('div');
    valueDiv.setAttribute('class', 'value');
    valueDiv.setAttribute('contentEditable', 'true');
    valueDiv.innerText = ZERO_WIDTH_NB_CHAR;
    containerDiv.appendChild(valueDiv);

    switch (headingSize) {
        case 1:
            const secondValueDiv = document.createElement('div');
            secondValueDiv.setAttribute('class', 'second-value');
            secondValueDiv.setAttribute('contentEditable', 'true');
            secondValueDiv.innerText = ZERO_WIDTH_NB_CHAR;
            containerDiv.appendChild(createInvisibleParagraphBreak());
            containerDiv.appendChild(secondValueDiv);
            break;
        case 2:
            break;
        case 3:
            const emDashDiv = document.createElement('div');
            emDashDiv.setAttribute('class', 'em-dash');
            emDashDiv.setAttribute('contentEditable', 'false');
            emDashDiv.innerHTML = '—&nbsp';
            containerDiv.insertBefore(emDashDiv, containerDiv.firstChild);
            break;
        case 4:
            break;
        case 5:
            break;
    }

    editorElement.appendChild(containerDiv);
    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementHeading(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'heading'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementHeading(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementHeading(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {HTMLElement | null}
 */
export function getClosestEditorElementHeading(node) {
    if (!node) return null;
    let walk = node;
    while (walk) {
        if (isEditorElementHeading(walk)) return walk;
        walk = walk.parentNode;
    }
    return node;
}

/**
 * @param editor {EditorCustom}
 * @param editorElement {HTMLElement}
 * @param headingSize {(1|2|3|4|5)}
 * @returns {undefined}
 */
function insertNewElementAtCursor(editor, editorElement, headingSize) {
    /**
     * @type {Node}
     */
    const documentFragment = document.createDocumentFragment();
    documentFragment.appendChild(editorElement);
    documentFragment.appendChild(createInvisibleParagraphBreak());
    /**
     * @type {Node}
     */
    const valueFragment = document.createDocumentFragment();
    /**
     * @type {Node}
     */
    const secondValueFragment = document.createDocumentFragment();
    const selectedParagraphs = getSelectedParagraphs(editor);
    if (selectedParagraphs.length) {
        for (const [
            paragraphIndex,
            paragraph,
        ] of selectedParagraphs.entries()) {
            const br = paragraph[paragraph.length - 1]?.nextElementSibling;
            if (br?.tagName === 'BR') {
                br.remove();
            }
            for (let node of paragraph) {
                switch (headingSize) {
                    case 1:
                        if (paragraphIndex === 0) {
                            valueFragment.appendChild(node);
                        } else {
                            secondValueFragment.appendChild(node);
                        }
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        valueFragment.appendChild(node);
                        break;
                }
            }
            if (paragraphIndex < selectedParagraphs.length - 1) {
                /**
                 * @type {HTMLElement}
                 */
                const br = document.createElement('br');
                switch (headingSize) {
                    case 1:
                        if (paragraphIndex !== 0) {
                            secondValueFragment.appendChild(br);
                        }
                        break;
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        valueFragment.appendChild(br);
                        break;
                }
            }
        }
        editorElement.querySelector('.value')?.appendChild(valueFragment);
        editorElement
            .querySelector('.second-value')
            ?.appendChild(secondValueFragment);
    }
    editor.selection.setNode(documentFragment);
}

/**
 * @param oldEditorElementHeading {HTMLElement}
 * @param oldEditorElementHeadingSize {(1|2|3|4|5)}
 * @param editorElement {HTMLElement}
 * @param headingSize {(1|2|3|4|5)}
 * @returns {undefined}
 */
function changeElementAtCursor(
    oldEditorElementHeading,
    oldEditorElementHeadingSize,
    editorElement,
    headingSize,
) {
    /**
     * @type {Node}
     */
    const valueFragment = document.createDocumentFragment();
    /**
     * @type {Node}
     */
    const secondValueFragment = document.createDocumentFragment();
    const oldValue = oldEditorElementHeading.querySelector('.value');
    switch (headingSize) {
        case 1:
            Array.from(oldValue.childNodes).forEach((node, index) => {
                if (index === 0) {
                    valueFragment.appendChild(node.cloneNode(true));
                } else {
                    if (index === 1 && node.tagName === 'BR') {
                        return;
                    }
                    secondValueFragment.appendChild(node.cloneNode(true));
                }
            });
            editorElement
                .querySelector('.second-value')
                ?.appendChild(secondValueFragment);
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            Array.from(oldValue.childNodes).forEach((node) => {
                valueFragment.appendChild(node.cloneNode(true));
            });
            break;
    }

    switch (oldEditorElementHeadingSize) {
        case 1:
            const oldSecondValue =
                oldEditorElementHeading.querySelector('.second-value');
            if (oldSecondValue.textContent.trim()) {
                /**
                 * @type {Node}
                 */
                const br = document.createElement('br');
                valueFragment.appendChild(br);
                Array.from(oldSecondValue.childNodes).forEach((node) => {
                    valueFragment.appendChild(node.cloneNode(true));
                });
            }
            break;
        case 2:
        case 3:
        case 4:
        case 5:
            break;
    }
    editorElement.querySelector('.value')?.appendChild(valueFragment);
    oldEditorElementHeading.replaceWith(editorElement);
}

/**
 * @implements {EditorElement}
 */
export class EditorElementHeading {
    /**
     * @param headingSize {(1|2|3|4|5)}
     */
    constructor(headingSize) {
        this.headingSize = headingSize;
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'heading';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementHeading(node);
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.value', '.second-value'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementHeading(editor, this.headingSize);
    }

    /**
     * @param {EditorCustom} editor
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selectedNode = editor.selection.getNode();
        const oldEditorElementHeading =
            getClosestEditorElementHeading(selectedNode);
        const oldEditorElementHeadingSize = Number(
            oldEditorElementHeading.getAttribute('data-heading-size'),
        );
        let editorElement = this.createEditorElement(editor);

        if (!isInsideEditorElementHeading(selectedNode)) {
            if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
                return false;
            }
        } else {
            if (oldEditorElementHeadingSize === this.headingSize) {
                return false;
            }
        }

        editor.undoManager.transact(() => {
            if (isInsideEditorElementHeading(selectedNode)) {
                changeElementAtCursor(
                    oldEditorElementHeading,
                    oldEditorElementHeadingSize,
                    editorElement,
                    this.headingSize,
                );
            } else {
                insertNewElementAtCursor(
                    editor,
                    editorElement,
                    this.headingSize,
                );
            }
            editor.focus();
            editorElement = editor.dom.get(editorElement.getAttribute('id'));
            const valueDiv = editorElement?.querySelector('.value');
            if (valueDiv) {
                const text = getClosestTextNode(valueDiv.lastChild);
                if (text) {
                    editor.selection.setCursorLocation(
                        text,
                        text.textContent.length,
                    );
                } else {
                    editor.selection.setCursorLocation(valueDiv, 0);
                }
            }

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const headingSize = Number(element.getAttribute('data-heading-size'));
        let txt = '';
        let textMaxLength = 0;
        let value = extractRecursively(
            element.querySelector('.value'),
            flags,
            editorElements,
            brailleDocument,
        );
        const secondValue = extractRecursively(
            element.querySelector('.second-value'),
            flags,
            editorElements,
            brailleDocument,
        );

        function setTextMaxLength(text) {
            const cleanedText = text.replace(/[\r\n]/g, '');
            if (cleanedText.length > textMaxLength)
                textMaxLength = cleanedText.length;
        }

        switch (headingSize) {
            case 1:
                for (let paragraph of value.split('\n')) {
                    if (!paragraph.length) continue;
                    txt += '\uFEFF'.repeat(10) + paragraph + '\n';
                    setTextMaxLength(paragraph);
                }
                for (let paragraph of secondValue.split('\n')) {
                    setTextMaxLength(paragraph);
                }
                txt +=
                    '\uFEFF'.repeat(10) + ':'.repeat(textMaxLength + 2) + '\n';
                for (let paragraph of secondValue.split('\n')) {
                    if (!paragraph.length) continue;
                    txt += '\uFEFF'.repeat(10) + paragraph + '\n';
                }
                break;
            case 2:
                for (let paragraph of value.split('\n')) {
                    if (!paragraph.length) continue;
                    txt += '\uFEFF'.repeat(10) + paragraph + '\n';
                    setTextMaxLength(paragraph);
                }
                txt +=
                    '\uFEFF'.repeat(10) + ':'.repeat(textMaxLength + 2) + '\n';
                break;
            case 3:
                for (let [index, paragraph] of value.split('\n').entries()) {
                    if (!paragraph.length) continue;
                    if (index === 0) {
                        txt += '-- ' + paragraph + '\n';
                    } else {
                        txt += '\uFEFF'.repeat(3) + paragraph + '\n';
                    }
                }
                break;
            case 4:
                for (let paragraph of value.split('\n')) {
                    if (!paragraph.length) continue;
                    txt += '\uFEFF'.repeat(10) + paragraph + '\n';
                    setTextMaxLength(paragraph);
                }
                break;
            case 5:
                txt += '<R+>\n';
                txt += value;
                if (txt.endsWith('\n')) {
                    txt = txt.substring(0, txt.length - 2);
                }
                txt += '\n<R->';
                break;
        }
        return txt;
    }

    /**
     * @param container {HTMLElement}
     */
    checkAndRepairElements(container) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...container.querySelectorAll('editor-element[type="heading"]'),
        ];
        for (let element of elements) {
            /**
             * @type {HTMLElement}
             */
            let value = element.querySelector('.value');
            if (!value) {
                value = document.createElement('div');
                value.className = 'value';
                value.contentEditable = 'true';
                value.innerHTML = ZERO_WIDTH_NB_CHAR;
                element.querySelector('.container')?.appendChild(value);
            }
            if (!value.innerText.trim().length) {
                value.innerHTML = ZERO_WIDTH_NB_CHAR;
            }

            switch (Number(element.getAttribute('data-heading-size'))) {
                case 1:
                    /**
                     * @type {HTMLElement}
                     */
                    let secondValue = element.querySelector('.second-value');
                    if (!secondValue) {
                        secondValue = document.createElement('div');
                        secondValue.className = 'second-value';
                        secondValue.contentEditable = 'true';
                        secondValue.innerHTML = ZERO_WIDTH_NB_CHAR;
                        element
                            .querySelector('.container')
                            ?.appendChild(secondValue);
                    }
                    if (!secondValue.innerText.trim().length) {
                        secondValue.innerHTML = ZERO_WIDTH_NB_CHAR;
                    }
                    break;
                case 2:
                case 3:
                case 4:
                case 5:
                    break;
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @returns {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    // /**
    //  * @param editor {EditorCustom}
    //  * @param element {HTMLElement}
    //  */
    prepareToBraille() {}

    // /**
    //  * @param editor {EditorCustom}
    //  * @param element {HTMLElement}
    //  */
    prepareToNotBraille() {}
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_HEADING_1] = new EditorElementHeading(1);
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_HEADING_2] = new EditorElementHeading(2);
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_HEADING_3] = new EditorElementHeading(3);
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_HEADING_4] = new EditorElementHeading(4);
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_HEADING_5] = new EditorElementHeading(5);
