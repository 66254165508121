import {
    generateId,
    getClosestEditorElement,
    getClosestElementRepresentation,
    getClosestElementRepresentationGenericSpace,
    getClosestPage,
    getClosestTextNode,
    isEditorElementParagraphBreak,
    isEditorElementRepresentationParagraphBreak,
    isInsideEditorElementRepresentation,
    isInsideNode,
    isInvisibleSpace,
} from './EditorUtil';
import { ZERO_WIDTH_NB_CHAR } from '../KeyboardModule';
import { getCurrentPage } from './PageManipulation';

import { getClosestElementAlignment } from './editor-element/EditorElementAlignment';
import {
    getEditorElementLinesCount,
    isInnerContextElement,
} from './EditorElements';

import { getInnerContextContainerClasses } from './editor-element/Instances';
import { isEditorBrailleView } from './BrailleView';
import {
    normalizeSpaces,
    removeInvisibleSpacesFromText,
} from '../../../../util/TextUtil';

/**
 * @param element {HTMLElement | DocumentFragment | ChildNode}
 * @param endNode {HTMLElement | ChildNode | null}
 * @param endOffset {number | null}
 * @param callback { (function(path: (string | HTMLElement)[], paragraph: number, word: number, node: HTMLElement, offset: number): boolean | undefined) | null }
 * @param scanInsideInnerContextElement {boolean | undefined}
 * @param includeParagraphBreaks {boolean | undefined}
 * @returns {(string | HTMLElement)[]}
 */
export function scanCaretPath(
    element,
    endNode = null,
    endOffset = null,
    callback = null,
    scanInsideInnerContextElement = false,
    includeParagraphBreaks = false,
) {
    const path = [];
    let paragraph = 0;
    let word = 0;
    if (!callback) callback = () => true;
    recursively(element);

    function recursively(node) {
        if (!node) return false;
        if (node.nodeType === Node.TEXT_NODE) {
            const content = normalizeSpaces(
                removeInvisibleSpacesFromText(node.textContent),
            );
            if (node === endNode) {
                if (!content.length || !endOffset) {
                    callback(path, paragraph, word, node, 0);
                }
                for (let i = 0; i < (endOffset ?? content.length); i++) {
                    const char = content.charAt(i);
                    if (char === '' || char === '\n') continue;
                    path.push(char);
                    if (callback(path, paragraph, word, node, i) === false)
                        return true;
                }
                return true;
            } else {
                if (!content.length) {
                    if (callback(path, paragraph, word, node, 0) === false)
                        return true;
                } else {
                    for (let i = 0; i < content.length; i++) {
                        const char = content.charAt(i);
                        if (char === '' || char === '\n') continue;
                        path.push(char);
                        if (callback(path, paragraph, word, node, i) === false)
                            return true;
                        if (char === ' ') word++;
                    }
                }
                return false;
            }
        } else {
            if (getClosestElementRepresentationGenericSpace(node)) {
                path.push(' ');
                word++;
                const continueScan = callback(path, paragraph, word, node, 1);
                if (node === endNode) return true;
                return continueScan === false;
            }
            if (isEditorElementRepresentationParagraphBreak(node)) {
                return node === endNode;
            }
            if (isEditorElementParagraphBreak(node)) {
                if (includeParagraphBreaks) {
                    path.push(node);
                    const continueScan = callback(
                        path,
                        paragraph,
                        word,
                        node,
                        0,
                    );
                    if (node === endNode) return true;
                    return continueScan === false;
                }
                return node === endNode;
            }
            // the paragraphs must be counted in <br> tags (visible or not)
            if (!scanInsideInnerContextElement && isInnerContextElement(node)) {
                path.push(node);
                const continueScan = callback(path, paragraph, word, node, 0);
                word = 0;
                if (node === endNode) return true;
                return continueScan === false;
            } else if (node.tagName === 'BR') {
                if (node === endNode) return true;
                path.push('\n');
                const continueScan = callback(path, paragraph, word, node, 0);
                paragraph++;
                word = 0;
                if (node === endNode) return true;
                return continueScan === false;
            }
            if (node === endNode) {
                return true;
            } else {
                for (let child of node.childNodes) {
                    if (isEditorBrailleView(child)) continue;
                    if (recursively(child)) return true;
                }
            }
        }
        return false;
    }

    return path;
}

/**
 * @typedef {object} CaretPosition
 * @property {(string | HTMLElement)[]} path
 * @property {(HTMLElement | Node)[]} nodePath
 * @property {HTMLElement | Node} page
 * @property {HTMLElement | Node} contextElement
 */

/**
 * @param editor {EditorCustom}
 * @param includeParagraphBreaks {boolean | undefined}
 * @returns { CaretPosition | null }
 */
export function getCaretPosition(editor, includeParagraphBreaks = false) {
    /**
     * @type {Node | HTMLElement | null}
     */
    let contextElement = getCurrentPage(editor);
    if (!contextElement) return null;
    const rng = editor.selection.getRng();
    /**
     * @type {Node | HTMLElement}
     */
    let endContainer = rng.endContainer;
    let endOffset = rng.endOffset;

    if (endContainer.nodeType !== Node.TEXT_NODE) {
        if (endOffset >= endContainer.childNodes.length) {
            endContainer = endContainer.nextSibling;
        } else {
            endContainer = endContainer.childNodes[endOffset];
        }
        endOffset = 0;
    }

    if (isEditorBrailleView(endContainer)) {
        // last element of page
        endContainer = endContainer.previousSibling;
    } else {
        let representation = getClosestElementRepresentation(endContainer);
        if (representation) {
            endContainer = representation;
        }
    }

    const nodePath = [];

    if (endContainer) {
        let context;

        function genIdContextElement() {
            if (!context.getAttribute('id')) {
                context.setAttribute('id', generateId(editor, 'element'));
            }
        }

        // inner context elements must be manually implemented bellow
        if (isInnerContextElement(endContainer, true)) {
            for (const containerClass of getInnerContextContainerClasses()) {
                context = endContainer.parentElement.closest(containerClass);
                if (context) {
                    break;
                } else {
                    if (
                        endContainer?.classList?.contains(
                            containerClass.substring(1),
                        )
                    ) {
                        context = endContainer;
                        break;
                    } else if (endContainer.querySelector) {
                        // sometimes the selection goes in a parent container
                        const child =
                            endContainer.querySelector(containerClass);
                        if (child) {
                            context = endContainer;
                            break;
                        }
                    }
                }
            }
            if (context) {
                contextElement = context;
                genIdContextElement();
            } else {
                contextElement = endContainer;
            }
        }
    }

    let path;
    if (endContainer) {
        path = scanCaretPath(
            contextElement,
            endContainer,
            endOffset,
            (path, paragraph, word, node) => {
                const idx = path.length - 1;
                if (!nodePath[idx]) nodePath.splice(idx, 0, node);
                // debug('scan', node, offset);
            },
            undefined,
            includeParagraphBreaks,
        );
    } else {
        // start of page when showing non-printable characters
        path = [];
    }

    // console.debug('getCaretPosition', contextElement, JSON.stringify(path));

    const currentPage = getClosestPage(contextElement);
    return {
        path,
        contextElement,
        nodePath,
        page: currentPage,
    };
}

/**
 * @param editor {EditorCustom}
 * @param contextElement {HTMLElement}
 * @param startPath {string[]}
 * @param endPath {string[]}
 */
export function selectRangePath(editor, contextElement, startPath, endPath) {
    /**
     * @type {null | HTMLElement}
     */
    let startElement = null;
    let startOffset = 0;
    /**
     * @type {null | HTMLElement}
     */
    let endElement = null;
    let endOffset = 0;

    let lastLength = 0;
    scanCaretPath(
        contextElement,
        null,
        null,
        (path, paragraph, word, node, offset) => {
            if (path.length !== lastLength) {
                lastLength = path.length;
            } else {
                return true;
            }
            const currentChar = path[path.length - 1];
            if (!startElement) {
                if (!startPath.length) {
                    startElement = node;
                    startOffset = offset;
                } else {
                    const char = startPath.shift();
                    if (char !== currentChar) {
                        throw new Error(
                            'Start path not applies in current context.',
                        );
                    }
                }
            }

            if (!endElement) {
                const char = endPath.shift();
                if (char !== currentChar) {
                    throw new Error('End path not applies in current context.');
                }
            }

            if (!endPath.length) {
                endElement = node;
                endOffset = offset + 1;
                if (startElement == null) {
                    throw new Error('End of selection found before the start.');
                }
                return false;
            }

            return true;
        },
    );

    if (!startElement || !endElement) {
        throw new Error('Invalid range. Start or end element not found.');
    }

    /**
     * @type {*}
     */
    const range = document.createRange();
    range.setStart(startElement, startOffset);
    range.setEnd(endElement, endOffset);
    editor.selection.setRng(range);
}

/**
 * @param editor {EditorCustom}
 * @param contextElement { HTMLElement | Node }
 * @param positionPath {string[]}
 * @param focus {boolean | null}
 */
export function setCaretPosition(
    editor,
    contextElement,
    positionPath,
    focus = true,
) {
    if (!contextElement) {
        return;
    }
    // console.debug('setCaretPosition', contextElement, JSON.stringify(positionPath));

    // sometimes the object is modified inside tinymce and instance changes
    if (contextElement.getAttribute('id')) {
        contextElement = editor.dom.get(contextElement.getAttribute('id'));
    }

    const top = positionPath.length === 0;
    let lastLength = null;
    positionPath = [...positionPath]; // clone the array
    scanCaretPath(
        contextElement,
        null,
        null,
        (path, paragraph, word, node, nodeOffset) => {
            if (lastLength === path.length) return true;
            lastLength = path.length;
            if (positionPath.length) {
                if (!lastLength) return true;
                const currentElement = path[path.length - 1];
                nodeOffset++;
                if (
                    positionPath[0] === currentElement ||
                    typeof currentElement === 'object'
                ) {
                    positionPath.shift();
                    if (positionPath.length) return true;
                } else {
                    console.error(
                        `Path diverges. Expected: '${positionPath[0]}'; Found: '${path[path.length - 1]}'`,
                    );
                    positionPath = [];
                }
            }

            if (node.nodeType === Node.TEXT_NODE) {
                // console.debug('setCaretPosition', 'Text selection', node, nodeOffset);
                let invisibleChars = 0;
                for (let i = 0; i < nodeOffset; i++) {
                    if (isInvisibleSpace(node.textContent.charAt(i)))
                        invisibleChars++;
                }
                if (nodeOffset + invisibleChars > node.textContent.length) {
                    editor.selection.setCursorLocation(
                        node,
                        node.textContent.length,
                    );
                } else {
                    editor.selection.setCursorLocation(
                        node,
                        nodeOffset + invisibleChars,
                    );
                }
                return false;
            } else {
                const closestEditorElement = getClosestEditorElement(
                    node.nextElementSibling,
                );
                if (isInsideEditorElementRepresentation(node)) {
                    // console.debug('setCaretPosition', 'Representation element selected');
                    if (top) {
                        const textNode =
                            document.createTextNode(ZERO_WIDTH_NB_CHAR);
                        node.before(textNode);
                        editor.selection.setCursorLocation(textNode, 1);
                    } else {
                        editor.selection.setCursorLocation(
                            getClosestTextNode(node),
                            1,
                        );
                    }
                    return false;
                } else if (
                    node.tagName === 'BR' &&
                    !top &&
                    node.nextSibling?.nodeType !== Node.TEXT_NODE &&
                    !getClosestElementAlignment(node.nextElementSibling) &&
                    // exception elements
                    !isInnerContextElement(closestEditorElement)
                ) {
                    // console.debug('setCaretPosition', 'BR selection', node);
                    // this avoids a bug in cursor (cursor lost), when key down is pressed in a page terminated
                    // with white lines
                    /**
                     * @type {Node}
                     */
                    const textNode =
                        document.createTextNode(ZERO_WIDTH_NB_CHAR);
                    node.after(textNode);
                    editor.selection.setCursorLocation(textNode, 0);
                    return false;
                }
                if (!top) node = node.nextSibling ?? node;
                // console.debug('setCaretPosition', 'Node selection', node);
                editor.selection.setCursorLocation(node, 0);
                return false;
            }
        },
    );
    if (focus) editor.focus();
}

/**
 * @param page {HTMLElement | Node}
 * @return {(string | HTMLElement)[]}
 */
export function getParagraphsInText(page) {
    /**
     * @type {(string | HTMLElement)[]}
     */
    const paragraphs = [];
    const path = scanCaretPath(page);
    let i = 0;
    for (let currentPath of path) {
        if (currentPath === '\n') {
            if (paragraphs[i] == null) {
                paragraphs[i] = '';
            }
            i++;
        } else if (typeof currentPath === 'string') {
            if (paragraphs[i] == null) {
                paragraphs[i] = '';
            }
            paragraphs[i] += normalizeSpaces(currentPath);
        } else {
            paragraphs[i] = currentPath;
        }
    }
    return paragraphs;
}

/**
 * @param node {Node | HTMLElement}
 * @param callback { ((lineCount: number, node: Node) => boolean) | null }
 * @param startingAtLine {number | undefined}
 * @returns {number | null}
 */
export function getLineCount(node, callback = null, startingAtLine = 0) {
    if (!node) return null;
    let lineCount = 0;

    let offsetLine = getEditorElementLinesCount(node);
    if (node.getAttribute && offsetLine) {
        for (let i = 0; i < offsetLine; i++) {
            lineCount++;
            if (
                callback &&
                callback(lineCount + startingAtLine, node) === false
            ) {
                return lineCount;
            }
        }
        return lineCount;
    }

    for (const child of [...node.childNodes]) {
        if (isEditorBrailleView(child)) continue;
        let stop = false;
        lineCount += getLineCount(
            child,
            (lineCount, child) => {
                if (!callback) return;
                stop = callback(lineCount, child) === false;
                return !stop;
            },
            lineCount + startingAtLine,
        );
        if (child.tagName === 'BR' || isEditorElementParagraphBreak(child)) {
            lineCount++;
        }
        if (stop) return lineCount + startingAtLine;
        if (callback && callback(lineCount + startingAtLine, child) === false)
            return lineCount + startingAtLine;
    }
    return lineCount;
}

/**
 * @param editor
 * @return { {page: Node | null, line: number } }
 */
export function getCaretLine(editor) {
    const currentPage = getCurrentPage(editor);
    let { endContainer, endOffset } = editor.selection.getRng();
    if (endOffset && endContainer.nodeType !== Node.TEXT_NODE) {
        endContainer = endContainer.childNodes[endOffset];
    }
    let foundLine = -1;
    getLineCount(currentPage, (lineCount, node) => {
        if (isInsideNode(node, endContainer)) {
            foundLine = lineCount;
            return false;
        }
    });
    return {
        page: currentPage,
        line: foundLine,
    };
}
