import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestEditorElementRecoil,
    getSelectedParagraphs,
    isInsideEditorElementRecoil,
} from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    breakParagraphsEditorElementContainer,
    setEditorElementLinesCount,
    clearEditorElementLinesCount,
    markLines,
} from '../EditorElements';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import {
    convertElementToBraille,
    removeParagraphBreaks,
} from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { getCaretPosition } from '../CaretPath';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';

export const EDITOR_ELEMENT_RECOIL = 'EDITOR_ELEMENT_RECOIL';

/**
 * @param editor {EditorCustom | null}
 * @param selection {string | null | undefined}
 * @returns {HTMLElement}
 */
export function createEditorElementRecoil(editor, selection = null) {
    const editorElement = document.createElement('editor-element');
    const elementId = generateId(editor, 'editor-element-recoil');
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'recoil');
    editorElement.setAttribute('id', elementId);

    const recoilText = document.createElement('div');
    recoilText.className = 'text';
    recoilText.setAttribute('contentEditable', 'true');
    recoilText.innerHTML = selection?.trim()
        ? selection.trim()
        : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(recoilText);

    return editorElement;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementRecoil {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'recoil';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementRecoil(node);
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null) {
        return createEditorElementRecoil(editor);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const editorElementRecoil = getClosestEditorElementRecoil(
            editor.selection.getNode(),
        );

        if (editorElementRecoil) {
            // remove recoil
            editor.undoManager.transact(() => {
                /**
                 * @type {Element | Node}
                 */
                const fragment = document.createDocumentFragment();
                /**
                 * @type {HTMLDivElement}
                 */
                const container = editorElementRecoil.querySelector('.text');
                removeParagraphBreaks(container, true);
                for (const child of [...container.childNodes]) {
                    fragment.appendChild(child);
                }
                /**
                 * @type {HTMLBRElement}
                 */
                const br = document.createElement('br');
                fragment.appendChild(br);
                editorElementRecoil.replaceWith(fragment);
                editor.focus();
                editor.selection.setCursorLocation(container, 0);

                /**
                 * @type {PageDataChangedEvent}
                 */
                const pageDataChangedEvent = {
                    caretPosition: getCaretPosition(editor),
                };
                editor.fire('pageDataChanged', pageDataChangedEvent);
            });
            return true;
        }

        let editorElement = createEditorElementRecoil(editor);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            const selectedParagraphs = getSelectedParagraphs(editor);
            if (selectedParagraphs.length !== 1 || selectedParagraphs.length) {
                // has content
                const contentFragment = document.createDocumentFragment();
                for (const [i, paragraph] of selectedParagraphs.entries()) {
                    const br =
                        paragraph[paragraph.length - 1]?.nextElementSibling;
                    if (br?.tagName === 'BR') {
                        br.remove();
                    }
                    for (const node of paragraph) {
                        contentFragment.appendChild(node);
                    }
                    if (i < selectedParagraphs.length - 1) {
                        contentFragment.appendChild(
                            document.createElement('br'),
                        );
                    }
                }
                editorElement
                    .querySelector('.text')
                    ?.appendChild(contentFragment);
            }
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.after(createInvisibleParagraphBreak());
            const textContext = editorElement.querySelector('.text');
            /**
             * @type {Text}
             */
            const cursorMark = document.createTextNode(ZERO_WIDTH_NB_CHAR);
            textContext.appendChild(cursorMark);
            editor.focus();
            editor.selection.setCursorLocation(cursorMark, 0);

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll('editor-element[type="recoil"]'),
        ];
        for (let element of elements) {
            let textContainer = element.querySelector('.text');
            if (!textContainer) {
                const textContainer = document.createElement('div');
                textContainer.className = 'text';
                textContainer.setAttribute('contentEditable', 'true');
                element.appendChild(textContainer);
            }
            if (!textContainer.textContent.trim()) {
                textContainer.textContent = ZERO_WIDTH_NB_CHAR;
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const container = element.querySelector('.text');
        if (rawOutput) {
            clearEditorElementLinesCount(element);
        }

        if (rawOutput) {
            let brailleData = convertElementToBraille(
                container,
                editorElements,
                brailleDocument,
                flags,
            );
            brailleData = markLines(brailleData, MARK_CHAR.RECOIL_BLOCK);
            const { breaks, paragraphs } = getBrailleParagraphs(
                brailleData,
                brailleDocument,
            );
            breakParagraphsEditorElementContainer(container, breaks);
            setEditorElementLinesCount(element, paragraphs.length);
            return markLines(paragraphs, MARK_CHAR.RAW_DATA).join('\r\n');
        } else {
            return `<R+>\r\n${extractRecursively(container, flags, editorElements, brailleDocument)}\r\n<R->`;
        }
    }
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_RECOIL] = new EditorElementRecoil();
