import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import {
    generateId,
    getClosestEditorElement,
    isEditorElement,
    isEditorElementImageLayout,
} from '../EditorUtil';
import {
    breakParagraphsEditorElementContainer,
    clearEditorElementLinesCount,
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    markLines,
    setEditorElementLinesCount,
} from '../EditorElements';
import { getImageUrlDataFromBackgroundImage } from 'plataforma-braille-common';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { getCaretPosition } from '../CaretPath';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import { getBrailleParagraphs } from '../BrailleView';
import { convertElementToBraille } from '../../../../../conversion/braille/HtmlToBraille';

export const EDITOR_ELEMENT_IMAGE = 'EDITOR_ELEMENT_IMAGE';
export const EDITOR_ELEMENT_IMAGE_LAYOUT = 'EDITOR_ELEMENT_IMAGE_LAYOUT';

/**
 * @param editor {EditorCustom | undefined | null}
 * @param legend {string | undefined | null}
 * @param description {string | undefined | null}
 * @param page {string | undefined | null}
 * @param image {string | undefined | null}
 * @return {HTMLElement}
 */
export function createEditorElementImage(
    editor = null,
    legend = null,
    description = null,
    page = null,
    image = null,
) {
    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-image';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('contentEditable', 'false');
    editorElement.setAttribute('type', 'image');
    editorElement.setAttribute('id', elementId);

    const infoContainer = document.createElement('div');
    infoContainer.setAttribute('class', 'info-container');
    editorElement.appendChild(infoContainer);

    const imageLegendDiv = document.createElement('div');
    imageLegendDiv.setAttribute('class', 'info-legend');
    imageLegendDiv.setAttribute('contentEditable', 'true');

    // I18N
    imageLegendDiv.setAttribute('data-placeholder', 'Legenda: ');
    editorElement.appendChild(infoContainer);
    infoContainer.appendChild(imageLegendDiv);
    imageLegendDiv.innerHTML = legend?.trim()
        ? legend.trim()
        : ZERO_WIDTH_NB_CHAR;

    const imageDescriptionDiv = document.createElement('div');
    imageDescriptionDiv.setAttribute('class', 'info-description');
    imageDescriptionDiv.setAttribute('contentEditable', 'true');

    // I18N
    imageDescriptionDiv.setAttribute('data-placeholder', 'Descrição: ');
    infoContainer.appendChild(imageDescriptionDiv);
    imageDescriptionDiv.innerHTML = description?.trim()
        ? description.trim()
        : ZERO_WIDTH_NB_CHAR;

    const pageContainer = document.createElement('div');
    pageContainer.setAttribute('class', 'page-container');
    editorElement.appendChild(pageContainer);

    const pageNumberDiv = document.createElement('div');
    pageNumberDiv.setAttribute('class', 'page-number');
    pageNumberDiv.setAttribute('contentEditable', 'true');

    // I18N
    pageNumberDiv.setAttribute('data-placeholder', 'Pág.: ');
    pageContainer.appendChild(pageNumberDiv);
    pageNumberDiv.innerHTML = page?.toString()
        ? page.toString()
        : ZERO_WIDTH_NB_CHAR;

    if (image) {
        editorElement.style.backgroundImage = `url("${image}")`;
    }

    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementImage(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'image'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementImage(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementImage(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param editor {EditorCustom | null}
 * @param legend {string | null | undefined}
 * @param description {string | null | undefined}
 * @param page {string | null | undefined}
 * @param image {string | null | undefined}
 * @returns {HTMLElement}
 */
export function createEditorElementImageLayout(
    editor,
    legend = null,
    description = null,
    page = null,
    image = null,
) {
    const pageNumberDiv = document.createElement('div');
    pageNumberDiv.setAttribute('class', 'page-number');
    pageNumberDiv.setAttribute('contentEditable', 'true');
    // I18N
    pageNumberDiv.setAttribute('data-placeholder', 'Pág.: ');
    pageNumberDiv.innerHTML = page?.toString()
        ? page?.toString()
        : ZERO_WIDTH_NB_CHAR;

    const imageLabelDiv = document.createElement('div');
    imageLabelDiv.setAttribute('class', 'info-legend');
    imageLabelDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageLabelDiv.setAttribute('data-placeholder', 'Legenda: ');
    imageLabelDiv.innerHTML = legend?.trim()
        ? legend.trim()
        : ZERO_WIDTH_NB_CHAR;

    const imageDescriptionDiv = document.createElement('div');
    imageDescriptionDiv.setAttribute('class', 'info-description');
    imageDescriptionDiv.setAttribute('contentEditable', 'true');
    // I18N
    imageDescriptionDiv.setAttribute('data-placeholder', 'Descrição: ');
    imageDescriptionDiv.innerHTML = description?.trim()
        ? description.trim()
        : ZERO_WIDTH_NB_CHAR;

    /**
     * @type {HTMLElement}
     */
    const editorElement = document.createElement('editor-element');
    const idPrefix = 'editor-element-image-layout';
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('type', 'image-layout');
    editorElement.setAttribute('contentEditable', 'false');
    if (image?.trim()) {
        editorElement.setAttribute('data-image', image);
    }
    editorElement.appendChild(document.createTextNode('_y'));
    editorElement.appendChild(pageNumberDiv);
    editorElement.appendChild(document.createTextNode(':\u00A0'));
    editorElement.appendChild(imageLabelDiv);
    editorElement.appendChild(document.createTextNode('\u00A0['));
    editorElement.appendChild(imageDescriptionDiv);
    editorElement.appendChild(document.createTextNode(']'));

    return editorElement;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementImageLayout(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementImageLayout(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @implements {EditorElement}
 */
export class EditorElementImage {
    constructor() {}

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'image';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementImage(node);
    }

    /**
     * @return {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.info-legend', '.info-description', '.page-number'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return false;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @return {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        flags.push(BrailleFacilConversionFlag.CONTEXT_IMAGE);

        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );

        const legendContainer = element.querySelector('.info-legend');
        const descriptionContainer = element.querySelector('.info-description');
        const pageNumberContainer = element.querySelector('.page-number');

        const renderBraille = (container) => {
            return rawOutput
                ? convertElementToBraille(
                      container,
                      editorElements,
                      brailleDocument,
                      flags,
                  )
                : extractRecursively(
                      container,
                      flags,
                      editorElements,
                      brailleDocument,
                  );
        };

        const legend = renderBraille(legendContainer);
        const pageNumber = renderBraille(pageNumberContainer);
        const description = renderBraille(descriptionContainer);

        if (!rawOutput) {
            if (!legend.trim() && !description?.trim() && !pageNumber?.trim()) {
                return '\r\n';
            }
            const brailleData = `_y${pageNumber.trim()}: ${legend.trim().length ? legend.trim() + ' ' : ''}_\`[${description.trim()}_\`]`;
            return '<R+>\r\n' + brailleData + '\r\n<R->\r\n';
        }

        clearEditorElementLinesCount(element);
        const brailleData = markLines(
            '_y' + pageNumber + ': ' + legend + ' _`[' + description + '_`]',
            MARK_CHAR.RECOIL_BLOCK,
        );
        let { breaks, paragraphs } = getBrailleParagraphs(
            brailleData,
            brailleDocument,
        );
        breakParagraphsEditorElementContainer(element, breaks, true);
        setEditorElementLinesCount(element, paragraphs.length);
        paragraphs = markLines(paragraphs, MARK_CHAR.RAW_DATA);

        return paragraphs.join('\r\n');
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param legend {string | undefined | null}
     * @param description {string | undefined | null}
     * @param page {string | undefined | null}
     * @param image {string | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(
        editor = null,
        legend = null,
        description = null,
        page = null,
        image = null,
    ) {
        return createEditorElementImage(
            editor,
            legend,
            description,
            page,
            image,
        );
    }

    /**
     * @param {EditorCustom} editor
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selection = editor.selection?.getContent().trim() ?? '';
        let editorElement = this.createEditorElement(editor, selection);

        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.after(createInvisibleParagraphBreak());
            editor.focus();
            editor.selection.setCursorLocation(
                editorElement.querySelector('.info-legend'),
                0,
            );

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param container {HTMLElement}
     */
    checkAndRepairElements(container) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...container.querySelectorAll('editor-element[type="image"]'),
        ];
        for (let element of elements) {
            // converts previous versions to most recently
            const needsConversionOrRepair =
                !!element.querySelector('.miniature img') || // first format
                !!element.getAttribute('data-legend') || // second format
                !!element.getAttribute('data-description') || // second format
                !!element.getAttribute('data-page') || // second format
                !!element.getAttribute('data-image') || // second format
                !element.querySelector('.info-container .info-legend') || // check if broken
                !element.querySelector('.info-container .info-description') || // check if broken
                !element.querySelector('.page-container .page-number'); // check if broken
            if (needsConversionOrRepair) {
                const legend =
                    element.getAttribute('data-legend') ??
                    element.querySelector('.info-legend')?.innerHTML;
                const description =
                    element.getAttribute('data-description') ??
                    element.querySelector('.info-description')?.innerHTML;
                const page =
                    element.getAttribute('data-page') ??
                    element.querySelector('.page-number')?.innerHTML;
                const image =
                    element.getAttribute('data-img') ??
                    element
                        .querySelector('.miniature img')
                        ?.getAttribute('data-src') ??
                    element.querySelector('.miniature img')?.src ??
                    getImageUrlDataFromBackgroundImage(
                        element.style.backgroundImage,
                    );
                const newElement = this.createEditorElement('', '', '', image);
                if (page)
                    newElement.querySelector('.page-number')?.replaceWith(page);
                if (legend)
                    newElement
                        .querySelector('.info-legend')
                        ?.replaceWith(legend);
                if (description)
                    newElement
                        .querySelector('.info-description')
                        ?.replaceWith(description);
                element.replaceWith(newElement);
                element = newElement;
            } else {
                const infoLegend = element.querySelector('.info-legend');
                const infoDescription =
                    element.querySelector('.info-description');
                const pageNumber = element.querySelector('.page-number');
                if (!infoLegend.innerText.trim())
                    infoLegend.innerHTML = ZERO_WIDTH_NB_CHAR;
                if (!infoDescription.innerText.trim())
                    infoDescription.innerHTML = ZERO_WIDTH_NB_CHAR;
                if (!pageNumber.innerText.trim())
                    pageNumber.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @param editor {EditorCustom}
     */
    removeMarkedAsRevised(editor) {
        const editorElement = getClosestEditorElement(
            editor.selection.getNode(),
        );
        if (isInsideEditorElementImage(editorElement)) {
            editor.undoManager.transact(() => {
                editorElement.removeAttribute('data-score-revised');
            });
        }
        editor.custom?.updateDocumentScore();
    }

    /**
     * @param editor {EditorCustom}
     */
    initialize(editor) {
        const self = this;
        editor.ui.registry.addMenuItem(
            'customContextMenuEditorElementImageRemoveMarkedAsRevised',
            {
                icon: 'close',
                // I18N
                text: 'Desaprovar descrição',
                onAction: function () {
                    self.removeMarkedAsRevised(editor);
                },
            },
        );
    }

    /**
     * @returns {string[]}
     */
    getContextMenu(element) {
        let menu = [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];

        const editorElement = getClosestEditorElement(element);
        if (editorElement.getAttribute('data-score-revised')) {
            menu = [
                'customContextMenuEditorElementImageRemoveMarkedAsRevised',
                '|',
                ...menu,
            ];
        }

        return menu;
    }

    /**
     * @param editor {EditorCustom}
     * @param element {HTMLElement}
     */
    prepareToNotBraille(editor, element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll('editor-element[type="image-layout"]'),
        ];
        for (const element of elements) {
            const legend = element.querySelector('.info-legend');
            const description = element.querySelector('.info-description');
            const pageNumber = element.querySelector('.page-number');
            const img = element.getAttribute('data-image');

            const editorElementImage =
                editor.custom.coreModule.editorElements.getEditorElementInstance(
                    EDITOR_ELEMENT_IMAGE,
                );
            const newElement = editorElementImage.createEditorElement(editor);
            newElement.querySelector('.info-legend').replaceWith(legend);
            newElement
                .querySelector('.info-description')
                .replaceWith(description);
            newElement.querySelector('.page-number').replaceWith(pageNumber);
            newElement.style.backgroundImage = img;
            element.replaceWith(newElement);
        }
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementImageLayout extends EditorElementImage {
    constructor() {
        super();
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return 'image-layout';
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementImageLayout(node);
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return false;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param legend {string | undefined | null}
     * @param description {string | undefined | null}
     * @param page {string | undefined | null}
     * @param image {string | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(
        editor = null,
        legend = null,
        description = null,
        page = null,
        image = null,
    ) {
        return createEditorElementImageLayout(
            editor,
            legend,
            description,
            page,
            image,
        );
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll('editor-element[type="image-layout"]'),
        ];
        for (let element of elements) {
            // converts previous versions to most recently
            const needsConversionOrRepair =
                !element.querySelector('.info-legend') || // check if broken
                !element.querySelector('.info-description') || // check if broken
                !element.querySelector('.page-number'); // check if broken
            if (needsConversionOrRepair) {
                const page = element.querySelector('.page-number');
                const legend = element.querySelector('.info-legend');
                const description = element.querySelector('.info-description');
                const image = element.getAttribute('data-image');
                const newElement = createEditorElementImageLayout(
                    null,
                    '',
                    '',
                    '',
                    image,
                );
                if (page)
                    newElement.querySelector('.page-number')?.replaceWith(page);
                if (legend)
                    newElement
                        .querySelector('.info-legend')
                        ?.replaceWith(legend);
                if (description)
                    newElement
                        .querySelector('.info-description')
                        ?.replaceWith(description);
                element.replaceWith(newElement);
                element = newElement;
            } else {
                const infoLegend = element.querySelector('.info-legend');
                const infoDescription =
                    element.querySelector('.info-description');
                const pageNumber = element.querySelector('.page-number');
                if (!infoLegend.innerText.trim())
                    infoLegend.innerHTML = ZERO_WIDTH_NB_CHAR;
                if (!infoDescription.innerText.trim())
                    infoDescription.innerHTML = ZERO_WIDTH_NB_CHAR;
                if (!pageNumber.innerText.trim())
                    pageNumber.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @param editor {EditorCustom}
     * @param element {HTMLElement}
     */
    prepareToBraille(editor, element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll('editor-element[type="image"]'),
        ];
        for (const element of elements) {
            const legend = element.querySelector('.info-legend');
            const description = element.querySelector('.info-description');
            const pageNumber = element.querySelector('.page-number');
            const img = element.style.backgroundImage;
            const newElement = createEditorElementImageLayout(
                editor,
                '',
                '',
                '',
                null,
            );
            newElement.querySelector('.info-legend').replaceWith(legend);
            newElement
                .querySelector('.info-description')
                .replaceWith(description);
            newElement.querySelector('.page-number').replaceWith(pageNumber);
            newElement.setAttribute('data-image', img);
            element.replaceWith(newElement);
        }
    }
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_IMAGE] = new EditorElementImage();
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_IMAGE_LAYOUT] =
    new EditorElementImageLayout();
