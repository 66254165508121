import {
    EditorCatalogSheet,
    EditorCatalogSheet2x,
    EditorElementHeading as EditorElementHeadingIcon,
    EditorElementHeading2x as EditorElementHeadingIcon2x,
    EditorMath,
    EditorMath2x,
    MenuFavorite,
    MenuFavorite2x,
    MenuHome,
    MenuHome2x,
} from '../../../../components/images';
import { createFileMenu } from './MenuFile';
import { createViewMenu } from './MenuView';
import { createEditMenu } from './MenuEdit';
import { createStructureMenu } from './MenuStructure';
import { createContextMenu } from './ContextMenu';
import { userCanEditDocumentPreferences } from '../../../UserPermissions';
import { EDITOR_ELEMENT_IMAGE } from '../core/editor-element/EditorElementImage';
import {
    EDITOR_ELEMENT_ALIGNMENT_CENTER,
    EDITOR_ELEMENT_ALIGNMENT_LEFT,
    EDITOR_ELEMENT_ALIGNMENT_RIGHT,
} from '../core/editor-element/EditorElementAlignment';
import * as EditorElementHeading from '../core/editor-element/EditorElementHeading';
import { EDITOR_ELEMENT_RECOIL } from '../core/editor-element/EditorElementRecoil';
import { isInsideEditorElementRecoil } from '../core/EditorUtil';

export const contexts = ['suppression', 'math', 'computer-related', 'catalog'];

export function addIcon(editor, iconName, icon1x, icon2x) {
    editor.ui.registry.addIcon(
        iconName,
        `<img src="${icon1x}" srcset="${icon1x} 1x, ${icon2x} 2x" alt="">`,
    );
}

/**
 * @typedef {object} MenuModuleParams
 * @property {function()} importPdfAction
 * @property {function()} exitAction
 * @property {function()} exportTxtAction
 * @property {function()} exportPdfAction
 * @property {function()} exportDocxAction
 * @property {function()} saveDocumentAction
 * @property {function()} removeDocumentAction
 * @property {function()} renameAction
 * @property {function()} hyphenationSettingsAction
 * @property {function()} documentSettingsAction
 * @property {function()} userWithAccessAction
 * @property {function(import('plataforma-braille-common').DocumentStatusEnum|string)|undefined} changeDocumentStatusAction
 * @property {function()} forceUnlockDocumentAction
 * @property {function()} retrieveImagesDescriptionsBackgroundAction
 * @property {function()} documentSettingsAction
 * @property {function()} dictionaryAction
 * @property {boolean} readOnly
 * @property {boolean} isAdmin
 * @property {boolean} isEvaluator
 * @property {boolean} isModerator
 * @property {boolean} isPrintShop
 * @property {boolean} isOwner
 * @property {import('plataforma-braille-common').RoleEnum[] | string[]} userDocumentRoles
 * @property {import('plataforma-braille-common').DocumentStatusEnum | string} status
 */

class MenuModule {
    /**
     * @param editor {EditorCustom}
     * @param params {MenuModuleParams}
     */
    constructor(editor, params) {
        this.editor = editor;
        this.params = params;
    }

    createEditorSuppression() {
        this.editor.ui.registry.addToggleButton('customEditorSuppression', {
            icon: 'remove-formatting',
            // I18N
            tooltip: 'Suprimir formatação',
            onAction: () =>
                this.editor.execCommand('mceToggleFormat', true, 'suppression'),
            onSetup: (api) => {
                // noinspection JSCheckFunctionSignatures
                api.setActive(this.editor.formatter.match('suppression'));
                const changed = this.editor.formatter.formatChanged(
                    'suppression',
                    (state) => api.setActive(state),
                );
                return () => changed.unbind();
            },
        });
    }

    createEditorMath() {
        addIcon(this.editor, 'customEditorMath', EditorMath, EditorMath2x);
        const self = this;
        this.editor.ui.registry.addToggleButton('customEditorMath', {
            icon: 'customEditorMath',
            // I18N
            tooltip: 'Expressão matemática',
            onAction: () =>
                self.editor.execCommand('mceToggleFormat', true, 'math'),
            onSetup: (api) => {
                // noinspection JSCheckFunctionSignatures
                api.setActive(self.editor.formatter.match('math'));
                const changed = self.editor.formatter.formatChanged(
                    'math',
                    (state) => api.setActive(state),
                );
                return () => changed.unbind();
            },
        });
    }

    createEditorComputerRelated() {
        addIcon(
            this.editor,
            'customEditorComputerRelated',
            EditorMath,
            EditorMath2x,
        );
        const self = this;
        this.editor.ui.registry.addToggleButton('customEditorComputerRelated', {
            icon: 'link',
            // I18N
            tooltip: 'Informático',
            onAction: () =>
                self.editor.execCommand(
                    'mceToggleFormat',
                    true,
                    'computerRelated',
                ),
            onSetup: (api) => {
                // noinspection JSCheckFunctionSignatures
                api.setActive(self.editor.formatter.match('computerRelated'));
                const changed = self.editor.formatter.formatChanged(
                    'computerRelated',
                    (state) => api.setActive(state),
                );
                return () => changed.unbind();
            },
        });
    }

    createEditorCatalog() {
        addIcon(
            this.editor,
            'customCatalogSheet',
            EditorCatalogSheet,
            EditorCatalogSheet2x,
        );
        const self = this;
        this.editor.ui.registry.addToggleButton('customEditorCatalog', {
            icon: 'customCatalogSheet',
            // I18N
            tooltip: 'Ficha catalográfica',
            onAction: () =>
                self.editor.execCommand('mceToggleFormat', true, 'catalog'),
            onSetup: (api) => {
                // noinspection JSCheckFunctionSignatures
                api.setActive(self.editor.formatter.match('catalog'));
                const changed = self.editor.formatter.formatChanged(
                    'catalog',
                    (state) => api.setActive(state),
                );
                return () => changed.unbind();
            },
        });
    }

    createEditorAlignment() {
        const formatChangedNames =
            'alignmentLeft,alignmentCenter,alignmentRight,suppression,math,computerRelated,catalog';
        const self = this;
        this.editor.ui.registry.addToggleButton('customEditorAlignLeft', {
            icon: 'align-left',
            // I18N
            tooltip: 'Alinhar a esquerda',
            onAction: () => {
                const { editorElements } = self.editor.custom.coreModule;
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_ALIGNMENT_LEFT,
                );
            },
            onSetup: (api) => {
                function isActive() {
                    return !self.editor.formatter.closest([
                        'alignmentCenter',
                        'alignmentRight',
                    ]);
                }
                api.setActive(isActive());
                let changed = self.editor.formatter.formatChanged(
                    formatChangedNames,
                    () => {
                        api.setActive(isActive());
                    },
                );
                return () => {
                    changed.unbind();
                };
            },
        });
        this.editor.ui.registry.addToggleButton('customEditorAlignCenter', {
            icon: 'align-center',
            // I18N
            tooltip: 'Centralizar',
            onAction: () => {
                const { editorElements } = self.editor.custom.coreModule;
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_ALIGNMENT_CENTER,
                );
            },
            onSetup: (api) => {
                function isActive() {
                    return !!self.editor.formatter.closest(['alignmentCenter']);
                }
                // noinspection JSCheckFunctionSignatures
                api.setActive(isActive());
                const changed = self.editor.formatter.formatChanged(
                    formatChangedNames,
                    () => {
                        api.setActive(isActive());
                    },
                );
                return () => changed.unbind();
            },
        });
        this.editor.ui.registry.addToggleButton('customEditorAlignRight', {
            icon: 'align-right',
            // I18N
            tooltip: 'Alinhar a direita',
            onAction: () => {
                const { editorElements } = self.editor.custom.coreModule;
                editorElements.insertElementAtCursor(
                    EDITOR_ELEMENT_ALIGNMENT_RIGHT,
                );
            },
            onSetup: (api) => {
                function isActive() {
                    return !!self.editor.formatter.closest(['alignmentRight']);
                }

                // noinspection JSCheckFunctionSignatures
                api.setActive(isActive());
                const changed = self.editor.formatter.formatChanged(
                    formatChangedNames,
                    () => {
                        api.setActive(isActive());
                    },
                );
                return () => changed.unbind();
            },
        });
    }

    createEditorImageReference() {
        const self = this;
        this.editor.ui.registry.addButton('customEditorImageReference', {
            icon: 'image',
            // I18N
            tooltip: 'Inserir referência de imagem',
            onAction: () => {
                const { editorElements } = self.editor.custom.coreModule;
                const editorElementImage =
                    editorElements.getEditorElementInstance(
                        EDITOR_ELEMENT_IMAGE,
                    );
                editorElementImage.insertElementAtCursor(self.editor);
            },
        });
    }

    createEditorHeading() {
        addIcon(
            this.editor,
            'customEditorHeading',
            EditorElementHeadingIcon,
            EditorElementHeadingIcon2x,
        );
        const self = this;
        this.editor.ui.registry.addMenuButton('customEditorHeading', {
            icon: 'customEditorHeading',
            // I18N
            tooltip: 'Inserir título',
            fetch: function (callback) {
                callback(
                    EditorElementHeading.getHeadingSubmenuItems(self.editor),
                );
            },
        });
    }

    createEditorRecoil() {
        const self = this;
        this.editor.ui.registry.addToggleButton('customEditorInsertRecoil', {
            icon: 'indent',
            // I18N
            tooltip: 'Recuo',
            onAction: () => {
                const { editorElements } = self.editor.custom.coreModule;
                editorElements.insertElementAtCursor(EDITOR_ELEMENT_RECOIL);
            },
            onSetup: (api) => {
                api.setActive(self.editor.formatter.match('recoil'));
                const changed = self.editor.formatter.formatChanged(
                    'recoil',
                    (state) => api.setActive(state),
                );
                return () => changed.unbind();
            },
        });
    }

    install() {
        addIcon(this.editor, 'customHome', MenuHome, MenuHome2x);
        addIcon(this.editor, 'customFavorite', MenuFavorite, MenuFavorite2x);

        createFileMenu(this.editor, this.params);
        createViewMenu(this.editor, this.params);

        if (
            userCanEditDocumentPreferences(
                this.params.isAdmin,
                this.params.isEvaluator,
                this.params.isModerator,
                this.params.isOwner,
                this.params.readOnly,
                this.params.userDocumentRoles,
            )
        ) {
            createEditMenu(this.editor, this.params);
            createStructureMenu(this.editor, this.params);
            createContextMenu(this.editor, this.params);
        }

        this.editor.ui.registry.addButton('customHome', {
            icon: 'customHome',
            // I18N
            tooltip: 'Home',
            onAction: this.params.exitAction,
        });

        // editor.ui.registry.addButton('customHomeFavorite', {
        //     icon: 'customFavorite',
        //     // I18N
        //     tooltip: 'Favoritos',
        //     disabled: false,
        //     onAction: favoritesAction,
        // });

        this.editor.on('init', () => {
            // I18N
            this.editor.formatter.register('suppression', {
                inline: 'editor-element',
                attributes: { type: 'suppression' },
            });
            this.editor.formatter.register('math', {
                inline: 'editor-element',
                attributes: { type: 'math' },
            });
            this.editor.formatter.register('computerRelated', {
                inline: 'editor-element',
                attributes: { type: 'computer-related' },
            });
            this.editor.formatter.register('catalog', {
                inline: 'editor-element',
                attributes: { type: 'catalog' },
            });
            this.editor.formatter.register('alignmentLeft', {
                inline: 'editor-element',
                attributes: { type: 'alignment-left' },
            });
            this.editor.formatter.register('alignmentCenter', {
                inline: 'editor-element',
                attributes: { type: 'alignment-center' },
            });
            this.editor.formatter.register('alignmentRight', {
                inline: 'editor-element',
                attributes: { type: 'alignment-right' },
            });
            this.editor.formatter.register('recoil', {
                inline: 'editor-element',
                attributes: { type: 'recoil' },
            });
        });

        this.createEditorSuppression();
        this.createEditorMath();
        this.createEditorComputerRelated();
        this.createEditorCatalog();
        this.createEditorImageReference();
        this.createEditorAlignment();
        this.createEditorHeading();
        this.createEditorRecoil();
    }
}

/**
 * @param editor {EditorCustom}
 * @param params {MenuModuleParams}
 */
export function installMenuModule(editor, params) {
    const menuModule = new MenuModule(editor, params);
    menuModule.install();
    return menuModule;
}
