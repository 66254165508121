import {
    getBrailleDocument,
    getClosestEditorElement,
    getClosestElementRepresentationGenericSpace,
    getClosestElementRepresentationLineBreak,
    getClosestPage,
    isEditorElement,
} from './EditorUtil';
import { ZERO_WIDTH_NB_CHAR, ZERO_WIDTH_SPACE_CHAR } from '../KeyboardModule';
import { getCaretPosition } from './CaretPath';
import { EDITOR_ELEMENTS_MAP } from './editor-element/Instances';
import {
    backPropagateBreaksToElement,
    removeParagraphBreaks,
} from '../../../../conversion/braille/HtmlToBraille';
import { setCustomParagraphBreaks } from './BrailleView';

/**
 * @typedef {object} EditorElement
 * @property {function(): string} getEditorElementType
 * @property {function(node: Node): boolean} isNodeInsideElement
 * @property {function(): string[]} getInnerContextContainerCssClass
 * @property {function(): boolean} worksNotConvertedToBraille
 * @property {function(): boolean} worksConvertedToBraille
 * @property {function(): boolean} hasInvisibleParagraphBreak
 * @property {(function(EditorCustom): HTMLElement) | (function(): HTMLElement)} createEditorElement
 * @property {function(EditorCustom): boolean} insertElementAtCursor
 * @property {function(HTMLElement, BrailleFacilConversionFlag[], EditorElements, BrailleDocument): string} convertToBraille
 * @property {function(HTMLElement)} checkAndRepairElements
 * @property {function(HTMLElement): string[]} getContextMenu
 * @property {undefined | function(EditorCustom, HTMLElement)} prepareToBraille
 * @property {undefined | function(EditorCustom, HTMLElement)} prepareToNotBraille
 * @property {undefined | function(EditorCustom)} initialize
 * @property {undefined | function} destroy
 */

/**
 * @param element {HTMLElement | Node}
 * @param checkInside {boolean | undefined}
 * @return {boolean}
 */
export function isInnerContextElement(element, checkInside = false) {
    if (checkInside) {
        element = getClosestEditorElement(element);
        if (!element) {
            return false;
        }
    } else {
        if (!isEditorElement(element)) {
            return false;
        }
    }

    for (const editorElement of Object.values(EDITOR_ELEMENTS_MAP)) {
        if (
            element.getAttribute('type') ===
            editorElement.getEditorElementType()
        ) {
            return !!editorElement.getInnerContextContainerCssClass().length;
        }
    }

    return false;
}

/**
 * @param editor {EditorCustom}
 * @param element {HTMLElement | Node}
 * @returns {boolean}
 */
export function elementCanBeInsertedAtSelection(editor, element) {
    if (!getClosestPage(editor.selection.getNode())) {
        editor.notificationManager.open({
            // I18N
            text: 'Não é possível inserir elementos fora da página',
            type: 'warning',
            timeout: 5000,
        });
        return false;
    } else if (isEditorElement(element)) {
        if (
            checkAncestorsElement(editor.selection.getNode(), (el) =>
                isEditorElement(el),
            )
        ) {
            let representation = getClosestElementRepresentationLineBreak(
                editor.selection.getNode(),
            );

            // exception when showing non-printable chars
            if (representation) {
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                representation.parentNode.insertBefore(
                    textNode,
                    representation,
                );
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }
            representation = getClosestElementRepresentationGenericSpace(
                editor.selection.getNode(),
            );
            if (representation) {
                /**
                 * @type {Node}
                 */
                const textNode = document.createTextNode(ZERO_WIDTH_NB_CHAR);
                representation.after(textNode);
                editor.selection.setCursorLocation(textNode, 0);
                return true;
            }

            editor.notificationManager.open({
                // I18N
                text: 'Não é possível inserir elementos em cascata',
                type: 'warning',
                timeout: 5000,
            });
            return false;
        }
    }
    return true;
}

/**
 * @param node { Node }
 * @param testFn { (element: HTMLElement | Node) => boolean }
 * @returns {boolean}
 */
export function checkAncestorsElement(node, testFn) {
    if (!node) return false;
    if (testFn(node)) return true;
    return checkAncestorsElement(node.parentNode, testFn);
}

/**
 * @returns {HTMLElement}
 */
export function createInvisibleParagraphBreak() {
    /**
     * @type {HTMLElement}
     */
    const br = document.createElement('br');
    br.style.display = 'none';
    return br;
}

/**
 * @param element {HTMLElement | Node}
 */
export function fixInvisibleParagraphBreak(element) {
    /**
     * @type {HTMLElement}
     */
    let br = element.nextElementSibling;
    if (br?.tagName === 'BR') {
        br.style.display = 'none';
    } else {
        br = document.createElement('br');
        br.style.display = 'none';
        element.after(br);
    }
}

/**
 * @param editor {EditorCustom}
 */
export function removeEditorElementSel(editor) {
    const node = getClosestEditorElement(editor.selection.getNode());
    if (!node) {
        return;
    }
    editor.undoManager.transact(() => {
        const caretPosition = getCaretPosition(editor);
        node.remove();
        /**
         * @type {PageDataChangedEvent}
         */
        const pageDataChangedEvent = { caretPosition: caretPosition };
        editor.fire('pageDataChanged', pageDataChangedEvent);
    });
}

/**
 * @param container {HTMLElement}
 * @param breaks {ParagraphBreak[]}
 * @param insideElements {boolean | undefined}
 */
export function breakParagraphsEditorElementContainer(
    container,
    breaks,
    insideElements = false,
) {
    removeParagraphBreaks(container, true);
    backPropagateBreaksToElement(container, breaks, insideElements);
    setCustomParagraphBreaks(container);
}

/**
 * @param element {HTMLElement}
 */
export function clearEditorElementLinesCount(element) {
    element.removeAttribute('data-lines-count');
}

/**
 * @param element {HTMLElement}
 * @param lineCount {number}
 */
export function setEditorElementLinesCount(element, lineCount) {
    element.setAttribute('data-lines-count', `${lineCount}`);
}

/**
 * @param brailleData {string | string[]}
 * @param mark {MARK_CHAR}
 * @returns {string | string[]}
 */
export function markLines(brailleData, mark) {
    let isArray = false;
    let array;
    if (Array.isArray(brailleData)) {
        isArray = true;
        array = brailleData;
    } else {
        array = brailleData.split('\r\n');
    }
    array = array.map((line) => mark + line + mark);
    if (isArray) return array;
    return array.join('\r\n');
}

/**
 * @param element {HTMLElement}
 * @return {number | null}
 */
export function getEditorElementLinesCount(element) {
    if (!element || !element.getAttribute) return null;
    let dataLineCount = element.getAttribute('data-lines-count');
    if (dataLineCount) {
        const numberValue = parseInt(dataLineCount);
        if (isNaN(numberValue)) return null;
        return numberValue;
    }
    return null;
}

/**
 * @param element {HTMLElement}
 */
export function surroundElementWithZeroWidthSpace(element) {
    /**
     * @type {Node}
     */
    let zeroWidthSpace;
    if (!element.previousSibling?.nodeValue?.endsWith(ZERO_WIDTH_SPACE_CHAR)) {
        zeroWidthSpace = document.createTextNode(ZERO_WIDTH_SPACE_CHAR);
        element.before(zeroWidthSpace);
    }
    if (!element.nextSibling?.nodeValue?.startsWith(ZERO_WIDTH_SPACE_CHAR)) {
        zeroWidthSpace = document.createTextNode(ZERO_WIDTH_SPACE_CHAR);
        element.after(zeroWidthSpace);
    }
}

export class EditorElements {
    /**
     * @param editor {EditorCustom | null}
     */
    constructor(editor) {
        this.editor = editor;
        this.initialize();
    }

    initialize() {
        for (const editorElement of Object.values(EDITOR_ELEMENTS_MAP)) {
            if (editorElement.initialize) {
                editorElement.initialize(this.editor);
            }
        }
    }

    /**
     * @param editorElementId {string}
     * @returns {EditorElement}
     */
    getEditorElementInstance(editorElementId) {
        const editorElement = EDITOR_ELEMENTS_MAP[editorElementId];
        if (!editorElement) {
            throw new Error(
                `Editor element does not exist: ${editorElementId}`,
            );
        }
        return editorElement;
    }

    /**
     * @param container {HTMLElement}
     */
    checkAndRepairElements(container) {
        const brailleDocument = getBrailleDocument(this.editor);
        for (const editorElement of Object.values(EDITOR_ELEMENTS_MAP)) {
            if (
                brailleDocument.convertedToBraille &&
                editorElement.prepareToBraille
            ) {
                editorElement.prepareToBraille(this.editor, container);
            } else if (
                !brailleDocument.convertedToBraille &&
                editorElement.prepareToNotBraille
            ) {
                editorElement.prepareToNotBraille(this.editor, container);
            }

            if (
                (brailleDocument.convertedToBraille &&
                    editorElement.worksConvertedToBraille()) ||
                (!brailleDocument.convertedToBraille &&
                    editorElement.worksNotConvertedToBraille())
            ) {
                editorElement.checkAndRepairElements(container);
            }
        }
    }

    /**
     * @param element {HTMLElement}
     * @returns {EditorElement | null}
     */
    getEditorElement(element) {
        for (const editorElement of Object.values(EDITOR_ELEMENTS_MAP)) {
            if (editorElement.isNodeInsideElement(element)) {
                return editorElement;
            }
        }
        return null;
    }

    /**
     * @param element {HTMLElement}
     * @return {string[] | string};
     */
    getContextMenu(element) {
        return this.getEditorElement(element)?.getContextMenu(element);
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @return {string | null}
     */
    convertToBraille(element, flags) {
        return (
            this.getEditorElement(element)?.convertToBraille(
                element,
                flags,
                this,
                getBrailleDocument(this.editor),
            ) ?? null
        );
    }

    /**
     * @param editorElementId {string}
     * @returns {boolean}
     */
    insertElementAtCursor(editorElementId) {
        const editorElement = this.getEditorElementInstance(editorElementId);
        if (!editorElement) {
            return false;
        }
        editorElement.insertElementAtCursor(this.editor);
        return true;
    }

    /**
     * @param container {HTMLElement}
     */
    removeLostParagraphBreak(container) {
        const brs = container.querySelectorAll('br');
        for (let element of brs) {
            if (element.style?.display !== 'none') continue;
            const partner = element.previousElementSibling;
            const editorElement = this.getEditorElement(partner);
            if (!partner || !editorElement?.hasInvisibleParagraphBreak()) {
                element.remove();
            }
        }
    }
}
