import { ZERO_WIDTH_NB_CHAR } from '../../KeyboardModule';
import { generateId, isEditorElement } from '../EditorUtil';
import {
    createInvisibleParagraphBreak,
    elementCanBeInsertedAtSelection,
    fixInvisibleParagraphBreak,
    breakParagraphsEditorElementContainer,
    setEditorElementLinesCount,
    clearEditorElementLinesCount,
    markLines,
} from '../EditorElements';
import { getCaretPosition } from '../CaretPath';
import { EDITOR_ELEMENTS_MAP } from './Instances';
import { convertElementToBraille } from '../../../../../conversion/braille/HtmlToBraille';
import { getBrailleParagraphs } from '../BrailleView';
import { MARK_CHAR } from '../../../../../conversion/braille/CharMap';
import { BrailleFacilConversionFlag } from '../../../../../conversion/txt/BrailleFacilConversionFlag';
import { extractRecursively } from '../../../../../conversion/txt/HtmlToBrailleFacil';

export const EDITOR_ELEMENT_ARROW_UP = 'EDITOR_ELEMENT_ARROW_UP';
export const EDITOR_ELEMENT_ARROW_DOWN = 'EDITOR_ELEMENT_ARROW_DOWN';

/**
 * @typedef {'arrow-up' | 'arrow-down'} ArrowType
 */

/**
 * @param editor {EditorCustom | null}
 * @param arrowType {ArrowType}
 * @param selection {string | null | undefined}
 */
export function createEditorElementArrow(editor, arrowType, selection = null) {
    const editorElement = document.createElement('editor-element');
    const idPrefix = `editor-element-${arrowType}`;
    const elementId = generateId(editor, idPrefix);
    editorElement.setAttribute('id', elementId);
    editorElement.setAttribute('type', arrowType);
    editorElement.setAttribute('contentEditable', 'false');

    const leftTextContainer = document.createElement('div');
    leftTextContainer.className = 'text-left';
    leftTextContainer.setAttribute('contentEditable', 'true');
    leftTextContainer.innerHTML = selection?.trim()
        ? selection.trim()
        : ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(leftTextContainer);

    const arrowContainer = document.createElement('div');
    arrowContainer.className = 'arrow-container';
    arrowContainer.appendChild(document.createElement('br'));
    arrowContainer.appendChild(document.createElement('br'));
    arrowContainer.setAttribute('contentEditable', 'false');
    editorElement.appendChild(arrowContainer);

    const rightTextContainer = document.createElement('div');
    rightTextContainer.className = 'text-right';
    rightTextContainer.setAttribute('contentEditable', 'true');
    rightTextContainer.innerHTML = ZERO_WIDTH_NB_CHAR;
    editorElement.appendChild(rightTextContainer);

    return editorElement;
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementArrowDown(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'arrow-down'
    );
}

/**
 * @param node {HTMLElement | Node}
 * @returns {boolean}
 */
export function isEditorElementArrowUp(node) {
    if (!node) return false;
    return (
        isEditorElement(node) &&
        node.getAttribute('type')?.toLowerCase() === 'arrow-up'
    );
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementArrowUp(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementArrowUp(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @param node {HTMLElement | Node | null}
 * @returns {boolean}
 */
export function isInsideEditorElementArrowDown(node) {
    if (!node) return false;
    let walk = node;
    while (walk) {
        if (isEditorElementArrowDown(walk)) return true;
        walk = walk.parentNode;
    }
    return false;
}

/**
 * @implements {EditorElement}
 */
class EditorElementArrow {
    /**
     * @type {string[]}
     */
    arrowPicture;

    /**
     * @param arrowType {ArrowType}
     */
    constructor(arrowType) {
        this.arrowType = arrowType;
        if (this.arrowType === 'arrow-up') {
            this.arrowPicture = ['ý?', ' l'];
        } else {
            this.arrowPicture = [' l', '^i'];
        }
    }

    /**
     * @returns {string}
     */
    getEditorElementType() {
        return this.arrowType;
    }

    /**
     * @returns {string[]}
     */
    getInnerContextContainerCssClass() {
        return ['.text-left', '.text-right'];
    }

    /**
     * @returns {boolean}
     */
    worksNotConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    worksConvertedToBraille() {
        return true;
    }

    /**
     * @returns {boolean}
     */
    hasInvisibleParagraphBreak() {
        return true;
    }

    /**
     * @param editor {EditorCustom | undefined | null}
     * @param selection {string | undefined | null}
     * @return {HTMLElement}
     */
    createEditorElement(editor = null, selection = null) {
        return createEditorElementArrow(editor, this.arrowType, selection);
    }

    /**
     * @param editor {EditorCustom}
     * @return {boolean}
     */
    insertElementAtCursor(editor) {
        const selection = editor.selection?.getContent().trim() ?? '';
        let editorElement = this.createEditorElement(editor, selection);
        if (!elementCanBeInsertedAtSelection(editor, editorElement)) {
            return false;
        }

        editor.undoManager.transact(() => {
            const id = editorElement.getAttribute('id');
            editor.selection.setContent(editorElement.outerHTML);
            editorElement = editor.dom.get(id);
            editorElement.after(createInvisibleParagraphBreak());
            editor.selection.setCursorLocation(
                editorElement.querySelector('.text-left'),
                0,
            );

            /**
             * @type {PageDataChangedEvent}
             */
            const pageDataChangedEvent = {
                caretPosition: getCaretPosition(editor),
            };
            editor.fire('pageDataChanged', pageDataChangedEvent);
        });

        return true;
    }

    /**
     * @param element {HTMLElement}
     */
    checkAndRepairElements(element) {
        /**
         * @type {HTMLElement[]}
         */
        const elements = [
            ...element.querySelectorAll(
                `editor-element[type="${this.arrowType}"]`,
            ),
        ];
        for (let element of elements) {
            const arrowContainer = element.querySelector('.arrow-container');
            let textLeft = element.querySelector('.text-left');
            let textRight = element.querySelector('.text-right');

            if (
                !arrowContainer ||
                !textLeft ||
                !textRight ||
                arrowContainer.querySelectorAll('br').length !== 2
            ) {
                // componente damaged
                const newElement = createEditorElementArrow(
                    null,
                    this.arrowType,
                );
                element.replaceWith(newElement);
                element = newElement;
                textLeft = element.querySelector('.text-left');
                textRight = element.querySelector('.text-right');
            }
            if (!textLeft.innerText.trim()) {
                textLeft.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
            if (!textRight.innerText.trim()) {
                textRight.innerHTML = ZERO_WIDTH_NB_CHAR;
            }
            fixInvisibleParagraphBreak(element);
        }
    }

    /**
     * @return {string[]}
     */
    getContextMenu() {
        return [
            'customContextMenuAddParagraphBreakAbove',
            'customContextMenuAddParagraphBreakBellow',
            '|',
            'customContextMenuRemove',
        ];
    }

    /**
     * @param element {HTMLElement}
     * @param flags {BrailleFacilConversionFlag[]}
     * @param editorElements {EditorElements}
     * @param brailleDocument {BrailleDocument}
     * @returns {string}
     */
    convertToBraille(element, flags, editorElements, brailleDocument) {
        const rawOutput = flags.includes(
            BrailleFacilConversionFlag.RAW_BRAILLE_OUTPUT,
        );
        const textLeftContainer = element.querySelector('.text-left');
        const textRightContainer = element.querySelector('.text-right');

        clearEditorElementLinesCount(element);

        const hasTextLeft = textLeftContainer.textContent.trim();
        const hasTextRight = textRightContainer.textContent.trim();
        let arrowPicture = [...this.arrowPicture];

        if (hasTextLeft) {
            arrowPicture = arrowPicture.map((line) => ` ${line}`); // adds a space separator at the left
        }
        if (hasTextRight) {
            arrowPicture = arrowPicture.map((line) => `${line} `); // adds a space separator at the right
        }

        const arrowPictureLargestLine = arrowPicture.reduce(
            (max, line) => Math.max(max, line.length),
            0,
        );

        let columnsLength =
            hasTextLeft && hasTextRight
                ? brailleDocument.brailleCellColCount / 2 -
                  arrowPictureLargestLine
                : brailleDocument.brailleCellColCount - arrowPicture[0].length;

        /**
         * @type {BrailleDocument}
         */
        const brailleDocumentCol = {
            ...brailleDocument,
            brailleCellColCount: columnsLength,
        };

        const renderBraille = (container) => {
            return rawOutput
                ? convertElementToBraille(
                      container,
                      editorElements,
                      brailleDocument,
                      flags,
                  )
                : extractRecursively(
                      container,
                      flags,
                      editorElements,
                      brailleDocument,
                  );
        };

        const brailleDataLeft = renderBraille(textLeftContainer);
        let { breaks: breaksLeft, paragraphs: resultingParagraphs } =
            getBrailleParagraphs(brailleDataLeft, brailleDocumentCol);

        const largestLeftLine = resultingParagraphs.reduce(
            (max, line) => Math.max(max, line.length),
            0,
        );

        while (resultingParagraphs.length < arrowPicture.length) {
            resultingParagraphs.push('');
        }
        for (let i = 0; i < arrowPicture.length; i++) {
            while (resultingParagraphs[i].length < largestLeftLine) {
                resultingParagraphs[i] += ' ';
            }
            resultingParagraphs[i] += arrowPicture[i];
        }

        const brailleDataRight = renderBraille(textRightContainer);
        const { breaks: breaksRight, paragraphs: paragraphsRight } =
            getBrailleParagraphs(brailleDataRight, brailleDocumentCol);

        while (resultingParagraphs.length < paragraphsRight.length) {
            resultingParagraphs.push('');
        }

        for (let i = 0; i < paragraphsRight.length; i++) {
            while (
                resultingParagraphs[i].length <
                largestLeftLine + arrowPictureLargestLine
            ) {
                resultingParagraphs[i] += ' ';
            }
            resultingParagraphs[i] += paragraphsRight[i];
        }

        if (rawOutput) {
            breakParagraphsEditorElementContainer(
                textLeftContainer,
                breaksLeft,
            );
            breakParagraphsEditorElementContainer(
                textRightContainer,
                breaksRight,
            );
            setEditorElementLinesCount(element, resultingParagraphs.length);

            resultingParagraphs = markLines(
                resultingParagraphs,
                MARK_CHAR.RAW_DATA,
            );
        } else {
            resultingParagraphs.unshift('<F->');
            resultingParagraphs.push('<F+>');
        }

        return resultingParagraphs.join('\r\n');
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementArrowUp extends EditorElementArrow {
    constructor() {
        super('arrow-up');
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementArrowUp(node);
    }
}

/**
 * @implements {EditorElement}
 */
export class EditorElementArrowDown extends EditorElementArrow {
    constructor() {
        super('arrow-down');
    }

    /**
     * @param node {Node}
     * @return {boolean}
     */
    isNodeInsideElement(node) {
        return isInsideEditorElementArrowDown(node);
    }
}

EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_ARROW_UP] = new EditorElementArrowUp();
EDITOR_ELEMENTS_MAP[EDITOR_ELEMENT_ARROW_DOWN] = new EditorElementArrowDown();
